import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import { DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';


import '../styles/dashboard.request.style.scss';

import loader from '../assets/gif/loader.gif';

import UserRequest from '../helpers/user.requests.helper';
import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';


const USR = new UserRequest();
const PATH__ = new PATH_();

function DashboardPatientRequest( props ) {
    const [viewTarif, setViewTarif] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const cost = [
        { "speciality": "Médecin Généraliste", "cost": [15000] },
        { "speciality": "Pédiatre", "cost": [34500] },
        { "speciality": "Cardiologue", "cost": [34404] },
        { "speciality": "Dentiste", "cost": [20694] },
        { "speciality": "Dermatologue", "cost": [20694] },
        { "speciality": "Gynécologue", "cost": [20694] },
        { "speciality": "Neurologue", "cost": [33896] },
        { "speciality": "Neuropsychiatre", "cost": [33896] },
        { "speciality": "Ophtalmologue", "cost": [20694] },
        { "speciality": "Oto-rhino-laryngologiste (ORL)", "cost": [20694] },
        { "speciality": "Orthopédiste", "cost": [20694] },
        { "speciality": "Pneumologue", "cost": [20694] },
        { "speciality": "Psychiatre", "cost": [33896] },
        { "speciality": "Rhumatologue", "cost": [20694] },
        { "speciality": "Urologue", "cost": [20694] }
    ];
      
    const [RDVDate, setRDVDate] = useState("");
    const [RDVTime, setRDVTime] = useState("");
    const [user, setUser] = useState({
        ID: "",
        nom : "",
        prenom : "",
        email : "",
        date_naissance: "",
        numero_telephone: "",
    });

    const [specialite, setSpecialite] = useState([]);
    const [address, setAddress] = useState([
        {"name": "Cotonou"},
        {"name": "Porto-Novo"},
        {"name": "Parakou"},
        {"name": "Abomey-Calavi"},
        {"name": "Djougou"},
        {"name": "Bohicon"},
        {"name": "Kandi"},
        {"name": "Lokossa"},
        {"name": "Ouidah"},
        {"name": "Abomey"},
        {"name": "Natitingou"},
        {"name": "Save"},
        {"name": "Nikki"},
        {"name": "Bembèrèkè"},
        {"name": "Malanville"},
        {"name": "Pobe"},
        {"name": "Savalou"},
        {"name": "Sakete"},
        {"name": "Come"},
        {"name": "Dogbo-Tota"},
        {"name": "Bassila"},
        {"name": "Banikoara"},
        {"name": "Ketou"},
        {"name": "Kouande"},
        {"name": "Tchaourou"},
        {"name": "Allada"},
        {"name": "Aplahoue"},
        {"name": "Dassa-Zoume"},
        {"name": "Beterou"},
        {"name": "Tanguieta"},
        {"name": "Copargo"},
        {"name": "N`Dali"},
        {"name": "N`Dali"},
        {"name": "Adjohoun"},
        {"name": "Comè"},
        {"name": "Kandi"}
    ]);
    const [searchValue, setSearchValue] = useState([]);
    const [searchAddress, setSearchAddress] = useState([]);
    
    const specialities = [
        "Médecin Généraliste",
        "Cardiologue",
        "Dermatologue",
        "Gynécologue",
        "Pédiatre",
    ];

    const formatResult = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    const formatResult_adress = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        if(currentUser!==null) {
            setUser(currentUser);
        }
        USR.get_specialite_list().then((res) => {
            setSpecialite(res.data.specialites.map((sp)=>{
                return {name: sp};
            }));
            USR.get_doctor_list().then((res) => {
                setSpecialite((prev)=>{
                    return( prev.concat(
                        res.data.medecins.map((md)=>{
                            return {name: md.nom + " " + md.prenom};
                        })
                    ))
                });
            });
        });
    },[]);

    function MakeRequest(data) {
        setIsLoading(true);
        localStorage.setItem("ilera-patient-dataForRequest", JSON.stringify(data));
        window.location.href = "/search/" + data.specialite;
    }
        
    return (
        <div className='request'>
            <div className="request_content">
                {/* <form action='#' method='POST' className='form' name='request'
                    onSubmit={(e) => {
                        e.preventDefault();
                        let date_naissance = new Date(document.forms["request"]["borne_month"].value + "-" + document.forms["request"]["borne_day"].value);
                        let year = date_naissance.getFullYear();
                        let month = (date_naissance.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                        let day = date_naissance.getDate().toString().padStart(2, '0');

                        let date_rdv = new Date(RDVDate);
                        let year_ = date_rdv.getFullYear();
                        let month_ = (date_rdv.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                        let day_ = date_rdv.getDate().toString().padStart(2, '0');
                        
                        let data = {
                            "utilisateur_id": user.ID,
                            "nom": user.nom,
                            "prenom": user.prenom,
                            "date_naissance": `${year}-${month}-${day}`,
                            "email": user.email,
                            "numero_telephone": user.numero_telephone,
                            "genre": document.forms["request"]["sex"].value,
                            "adresse": document.forms["request"]["address"].value,
                            "date_rdv": `${year_}-${month_}-${day_}`,
                            "heure_debut": RDVTime,
                            "specialite_medecin": document.forms["request"]["specialite_medecin"].value,
                            "motif_consultation": document.forms["request"]["motif_consultation"].value
                        }
                        MakeRequest(data);
                    }}
                >
                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Nom</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.nom} name='lastName' required placeholder='nom' type='text'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            nom: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre nom</span>
                            </div>
                        </div>

                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Prénom</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.firstName} name='firstName' required placeholder='Prénom' type='text'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            prenom: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre prénom</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Date de naissance</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='borne_month' required placeholder='dd/mm/yy' type='month'/>
                            </div>
                        </div>
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Jour de naissance</span>
                            </div>
                            <div className='form_input_balise'>
                                <select name="borne_day">
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Sexe à l'état civil</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='sex' value="Féminin" id='female' type='radio'/>
                                <label htmlFor="female">Féminin</label>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span></span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='sex' value="Masculin" id='mal' type='radio'/>
                                <label htmlFor="mal">Masculin</label>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Numéro de téléphone</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.numero_telephone} name='phoneNumber' required placeholder='Prénom' type='tel'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            numero_telephone: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre numéro</span>
                            </div>
                        </div>

                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Email</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.email} name='email' required placeholder='' type='email'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            email: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre email</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex'>
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Adresse</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='address' placeholder='' type='text'/>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <div className='form'>
                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Spécialité ou type de médecin recherché</span>
                                </div>
                                <div className='form_input_balise'>
                                    <select name="specialite_medecin">
                                        {
                                            cost.map((sp, index) => (
                                                <option value={sp.speciality}>
                                                    {sp.speciality}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Motif de la consultation</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <textarea name="motif_consultation" required/>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>
                            </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Préférences de date et d'heure</span>
                                </div>
                                <div className='form_input_balise'>
                                    <div className='flex datePickerContent'>
                                        <div className='datePickerContent_'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateCalendar']}>
                                                    <DateCalendar
                                                        referenceDate={dayjs(new Date())}
                                                        views={['year', 'month', 'day']}
                                                        onChange={(e)=>{
                                                            setRDVDate(new Date(e.$d).toISOString());
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        <div className='datePickerContent_'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'StaticTimePicker',
                                                    ]}
                                                >
                                                    
                                                    <DemoItem>
                                                        <StaticTimePicker defaultValue={dayjs(new Date())} 
                                                            onChange={(e)=>{
                                                                // console.log(e);
                                                                setRDVTime(e.$H + ":" + e.$m + ":" + e.$s);
                                                            }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className='login_content_form_button'>
                            <button>
                                Soumettre
                            </button>
                        </div>
                    </div>
                </form> */}
                <form className='homeViewContent_content_search' method='post' action='#' name="RDVRequest"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if(searchValue!=="") {
                                let data = {
                                    "utilisateur_id": user.ID,
                                    "adresse": searchAddress,
                                    "specialite": searchValue,
                                    "nom": "",
                                    "prenom": "",
                                    "date_naissance": "",
                                    "email": "",
                                    "numero_telephone": "",
                                    "genre": "",
                                    "date_rdv": "",
                                    "heure_debut": "",
                                    "motif_consultation": ""
                                };
                                // console.log(data);
                                MakeRequest(data);
                            }
                        }}
                    >
                        <div className='homeViewContent_content_search_input name'>
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                            </svg>
                            {/* <input type='search' name='searchInfo' placeholder='Nom, généraliste, spécialité, etc...'/> */}
                            <ReactSearchAutocomplete
                                items={specialite}
                                onSearch={(string, results)=>{
                                    setSearchValue(string);
                                }}
                                // onHover={handleOnHover}
                                onSelect={(item)=>{
                                    setSearchValue(item.name);
                                }}
                                // onFocus={handleOnFocus}
                                // autoFocus
                                formatResult={formatResult}
                                // inputSearchString={searchValue}
                                placeholder='Nom, généraliste, spécialité, etc...'
                                className="homeViewContent_content_search_input_balise"
                                showIcon={false}
                                styling={{
                                    borderRadius: "0px",
                                    margin: "0px",
                                    boxShadow: "0px 0px 0px 0px #00000000",
                                    borderColor: "transparent",
                                    height: "50px",
                                }}
                            />
                        </div>
                        <div className='homeViewContent_content_search_input location'>
                            <svg width="1em" height="1em" viewBox="-4 0 32 32" version="1.1" >
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                    <g id="Icon-Set" transform="translate(-104.000000, -411.000000)" fill="currentColor">
                                        <path d="M116,426 C114.343,426 113,424.657 113,423 C113,421.343 114.343,420 116,420 C117.657,420 119,421.343 119,423 C119,424.657 117.657,426 116,426 L116,426 Z M116,418 C113.239,418 111,420.238 111,423 C111,425.762 113.239,428 116,428 C118.761,428 121,425.762 121,423 C121,420.238 118.761,418 116,418 L116,418 Z M116,440 C114.337,440.009 106,427.181 106,423 C106,417.478 110.477,413 116,413 C121.523,413 126,417.478 126,423 C126,427.125 117.637,440.009 116,440 L116,440 Z M116,411 C109.373,411 104,416.373 104,423 C104,428.018 114.005,443.011 116,443 C117.964,443.011 128,427.95 128,423 C128,416.373 122.627,411 116,411 L116,411 Z" id="location"></path>
                                    </g>
                                </g>
                            </svg>
                            {/* <input type='search' name='searchAddress' placeholder='Où'/> */}
                            <ReactSearchAutocomplete
                                items={address}
                                onSearch={(string, results)=>{
                                    setSearchAddress(string);
                                    // console.log(string);
                                }}
                                // onHover={handleOnHover}
                                onSelect={(item)=>{
                                    setSearchAddress(item.name);
                                }}
                                // onFocus={handleOnFocus}
                                formatResult={formatResult_adress}
                                // inputSearchString={searchAddress}
                                placeholder='Où'
                                className="homeViewContent_content_search_input_balise"
                                showIcon={false}
                                styling={{
                                    borderRadius: "0px",
                                    margin: "0px",
                                    boxShadow: "0px 0px 0px 0px #00000000",
                                    borderColor: "transparent",
                                    height: "50px",
                                }}
                            />
                        </div>
                        <div className='homeViewContent_content_search_button'>
                            <button>
                                Rechercher
                            </button>
                        </div>
                    </form>
            </div>

            {
                viewTarif ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setViewTarif(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Sellectionnez le tarif qui vous convient le mieux
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <section className='table_content'>
                                    <table class="table table--bordered" align="center">
                                        <caption class="table__title">Tarifs de base d'une consultation pour les médecins spécialistes</caption>
                                        <thead class="table--secondary">
                                            <tr>
                                                <th>Médecins spécialistes</th>
                                                <th>Tarifs de consultation du médecin</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cost.map((ct, index) => (
                                                    <tr class="cell--center">
                                                        <th>{ct.speciality}</th>
                                                        <td>✔️ {ct.cost}xof</td>
                                                        <td>
                                                            <button
                                                                className='tab-button-1'
                                                                onClick={(e) => {
                                                                    window.location.href =  PATH__.api_root + 'paiement/paiement_facture/'+user.ID+'/'+ct.cost+'/'+ct.speciality+"/";
                                                                    // window.location.href =  '/payment?coast='+ct.cost+'&speciality='+ct.speciality;
                                                                }}
                                                            >
                                                                Choisir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </section>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPatientRequest);