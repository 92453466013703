import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    useParams
} from "react-router-dom";



import '../styles/homeView.screen.style.scss';
import '../styles/searchDoctor.screen.style.scss';

import UserRequest from '../helpers/user.requests.helper';
import PATH_ from '../GLOBAL-VAR/globals-urls';

/**Importation des images */
import loader from '../assets/gif/loader.gif';

/**Importation des composants */
import CardEvodoun from '../components/card_doctor/card_doctor.component';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const USR = new UserRequest();
const PATH__ = new PATH_();

function SearchDoctorView( props ) {
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(false);
    const [specialite, setSpecialite] = useState([]);
    const [userMatching, setUserMatching] = useState([]);
    const [matching, setMatching] = useState([]);
    const [confirm_, setConfirm_] = useState(null);
    const [currentDoctor, setCurrentDoctor] = useState(null);
    const [currentTarif, setCurrentTarif] = useState("video");
    const [dataForRequest, setDataForRequest] = useState(null);

    const address = [
        {"name": "Cotonou"},
        {"name": "Porto-Novo"},
        {"name": "Parakou"},
        {"name": "Abomey-Calavi"},
        {"name": "Djougou"},
        {"name": "Bohicon"},
        {"name": "Kandi"},
        {"name": "Lokossa"},
        {"name": "Ouidah"},
        {"name": "Abomey"},
        {"name": "Natitingou"},
        {"name": "Save"},
        {"name": "Nikki"},
        {"name": "Bembèrèkè"},
        {"name": "Malanville"},
        {"name": "Pobe"},
        {"name": "Savalou"},
        {"name": "Sakete"},
        {"name": "Come"},
        {"name": "Dogbo-Tota"},
        {"name": "Bassila"},
        {"name": "Banikoara"},
        {"name": "Ketou"},
        {"name": "Kouande"},
        {"name": "Tchaourou"},
        {"name": "Allada"},
        {"name": "Aplahoue"},
        {"name": "Dassa-Zoume"},
        {"name": "Beterou"},
        {"name": "Tanguieta"},
        {"name": "Copargo"},
        {"name": "N`Dali"},
        {"name": "N`Dali"},
        {"name": "Adjohoun"},
        {"name": "Comè"},
        {"name": "Kandi"}
    ];
    const [searchAddress, setSearchAddress] = useState([]);

    const { type } = useParams();

    const [user, setUser] = useState({
        ID: "",
        nom : "",
        prenom : "",
        email : "",
        date_naissance: "",
        numero_telephone: "",
    });

    const formatResult = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    const formatResult_adress = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    const currentUserDataRequest = JSON.parse(localStorage.getItem("ilera-patient-dataForRequest"));

    function getUserMatching(data) {
        setIsLoading(true);
        USR.startMatching(data).then((response) => {
            setIsLoading(false);
            if(response.data.success === false ) {
                if(response.data.message === "Aucun résultat trouvé.") {
                    setUserMatching([]);
                }
            } else {
                setUserMatching(response.data.medecins);
            }
            // console.log(response);
            
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function getAllDoctorListe() {
        setIsLoading(true);
        USR.get_all_doctor_list().then((response) => {
            setIsLoading(false);
            if(response.data.success === false ) {
                if(response.data.message === "Aucun résultat trouvé.") {
                    setMatching([]);
                }
            } else {
                setMatching(Object.entries(response.data.medecins_par_specialite).map(([specialite, medecins]) => ({ specialite, list: medecins })));
                // console.log(medecinsArray);
            }
            // console.log(response);
            
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function MakeRequest(data) {
        localStorage.setItem("ilera-patient-dataForRequest", JSON.stringify(data));
        window.location.href = "/search/" + data.specialite;
    }
    
    useEffect(() => {
        // console.log(PATH__.api_root);
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        if(currentUser!==null) {
            setUser(currentUser);
        }
        if(type!=="specialistes") {
            setSearchValue(type);
            let data = {};
            if(currentUserDataRequest !== null && currentUserDataRequest.adresse) {
                data = {
                    search: currentUserDataRequest.specialite,
                    // adresse : currentUserDataRequest.adresse
                }
            } else {
                data = {
                    search: currentUserDataRequest.specialite,
                }
            }
                
            getUserMatching(data);
        } else {
            setSearchValue("");
            getAllDoctorListe();
        }
        
        const dataForRequest_ = JSON.parse(localStorage.getItem("ilera-patient-dataForRequest"));
        if(dataForRequest_!==null) {
            setDataForRequest(dataForRequest_);
        }

        USR.get_specialite_list().then((res) => {
            setSpecialite(res.data.specialites.map((sp)=>{
                return {name: sp};
            }));
            USR.get_doctor_list().then((res) => {
                setSpecialite((prev)=>{
                    return( prev.concat(
                        res.data.medecins.map((md)=>{
                            return {name: md.nom + " " + md.prenom};
                        })
                    ))
                });
                // console.log(res.data);
                // res.data.specialites.map((sp)=>{
                //     console.log(sp);
                // });
            });
        });
    },[type]);

    return (
        <div className='homeViewContent'>
            <section class="searchView_content">
                <form className='homeViewContent_content_search' method='post' action='#' name="RDVRequest"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if(searchValue!=="") {
                            let data = {
                                "utilisateur_id": user.ID,
                                "adresse": searchAddress,
                                "specialite": searchValue,
                                "nom": "",
                                "prenom": "",
                                "date_naissance": "",
                                "email": "",
                                "numero_telephone": "",
                                "genre": "",
                                "date_rdv": "",
                                "heure_debut": "",
                                "motif_consultation": ""
                            };
                            // console.log(data);
                            MakeRequest(data);
                        }
                    }}
                >
                    <div className='homeViewContent_content_search_input name'>
                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                        </svg>
                        {/* <input type='search' name='searchInfo' placeholder='Nom, généraliste, spécialité, etc...'/> */}
                        <ReactSearchAutocomplete
                            items={specialite}
                            onSearch={(string, results)=>{
                                setSearchValue(string);
                            }}
                            // onHover={handleOnHover}
                            onSelect={(item)=>{
                                setSearchValue(item.name);
                            }}
                            // onFocus={handleOnFocus}
                            // autoFocus
                            formatResult={formatResult}
                            inputSearchString={searchValue}
                            placeholder='Nom, généraliste, spécialité, etc...'
                            className="homeViewContent_content_search_input_balise"
                            showIcon={false}
                            styling={{
                                borderRadius: "0px",
                                margin: "0px",
                                boxShadow: "0px 0px 0px 0px #00000000",
                                borderColor: "transparent",
                                height: "50px",
                            }}
                        />
                    </div>
                    <div className='homeViewContent_content_search_input location'>
                        <svg width="1em" height="1em" viewBox="-4 0 32 32" version="1.1" >
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                <g id="Icon-Set" transform="translate(-104.000000, -411.000000)" fill="currentColor">
                                    <path d="M116,426 C114.343,426 113,424.657 113,423 C113,421.343 114.343,420 116,420 C117.657,420 119,421.343 119,423 C119,424.657 117.657,426 116,426 L116,426 Z M116,418 C113.239,418 111,420.238 111,423 C111,425.762 113.239,428 116,428 C118.761,428 121,425.762 121,423 C121,420.238 118.761,418 116,418 L116,418 Z M116,440 C114.337,440.009 106,427.181 106,423 C106,417.478 110.477,413 116,413 C121.523,413 126,417.478 126,423 C126,427.125 117.637,440.009 116,440 L116,440 Z M116,411 C109.373,411 104,416.373 104,423 C104,428.018 114.005,443.011 116,443 C117.964,443.011 128,427.95 128,423 C128,416.373 122.627,411 116,411 L116,411 Z" id="location"></path>
                                </g>
                            </g>
                        </svg>
                        {/* <input type='search' name='searchAddress' placeholder='Où'/> */}
                        <ReactSearchAutocomplete
                            items={address}
                            onSearch={(string, results)=>{
                                setSearchAddress(string);
                                // console.log(string);
                            }}
                            // onHover={handleOnHover}
                            onSelect={(item)=>{
                                setSearchAddress(item.name);
                            }}
                            // onFocus={handleOnFocus}
                            formatResult={formatResult_adress}
                            // inputSearchString={searchAddress}
                            placeholder='Où'
                            className="homeViewContent_content_search_input_balise"
                            showIcon={false}
                            styling={{
                                borderRadius: "0px",
                                margin: "0px",
                                boxShadow: "0px 0px 0px 0px #00000000",
                                borderColor: "transparent",
                                height: "50px",
                            }}
                            
                        />
                    </div>
                    <div className='homeViewContent_content_search_button'>
                        <button>
                            Rechercher
                        </button>
                    </div>
                </form>
            </section>

            <section className="search_result">
                <h3>{userMatching.length===0 && matching.length===0? "Aucun" : userMatching.length || matching.length} résultats</h3>
                {
                    (userMatching.length > 0  || matching.length > 0 )&&
                    <span className="search_result_descb">
                        Trouvez votre spécialiste
                    </span>
                }
                
                <div className="search_result_list">
                    {
                        userMatching.map((doctor, index)=>(
                            <article className="doctorSummary" key={'doctor : ' + doctor.name + index}>
                                <CardEvodoun content={doctor} click_={(e)=>{
                                    setConfirm_(true);
                                    setCurrentDoctor(doctor);
                                }} />
                            </article>
                        ))
                    }

                    {
                        matching.map((doctor, index)=>(
                            <div className='search_result_list_specialiteList'>
                                <h3 className='search_result_list_specialiteTitle'>
                                    {doctor.specialite}
                                </h3>
                                <div className='search_result_list_specialiteList_content'>
                                    {
                                        doctor.list.map((dcr, index) => (
                                            <article className="doctorSummary" key={'doctor : ' + dcr.nom + index}>
                                                <CardEvodoun content={dcr} click_={(e)=>{
                                                    setConfirm_(true);
                                                    setCurrentDoctor(dcr);
                                                }} />
                                            </article>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
            {
                confirm_ ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setConfirm_(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Confirmez votre rendez-vous avec le docteur <strong>{currentDoctor.prenom} {currentDoctor.nom}</strong>
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    let date_rdv = new Date(dataForRequest.date_rdv);
                                    let year_ = date_rdv.getFullYear();
                                    let month_ = (date_rdv.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                                    let day_ = date_rdv.getDate().toString().padStart(2, '0');
                                    let data = {
                                        ...dataForRequest,
                                        date_rdv: `${year_}-${month_}-${day_}`
                                    };
                                    // console.log(data);
                                    // MakeRequest(data);
                                    USR.makeRequest(data).then((response) => {
                                        setIsLoading(false);
                                        let res = response.data;
                                        localStorage.setItem("ilera-patient-request", JSON.stringify(res));
                                        localStorage.setItem("ilera-patient-requestDoctor", JSON.stringify(currentDoctor));
                                        if(res.success === true) {
                                            alert("Votre demande a bien été prise en compte. Veuillez maintenant procéder au paiement.");
                                            let ct =  0;
                                            if(currentTarif === "video") {
                                                ct = 3000;
                                            } else if(currentTarif === "cabinet") {
                                                ct = 2500;
                                            } else {
                                                ct = 4000;
                                            }
                                            window.location.href =  PATH__.api_root + 'paiement/paiement_facture/'+user.ID+'/'+ct+'/'+data.specialite+"/";
                                        } else {
                                            if(response.data.error) {
                                                alert(response.data.error);
                                            }
                                        }
                                    }).catch((e) => {
                                        console.log(e.response);
                                        setIsLoading(false);
                                    });
                                }}
                            >
                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Tarifs</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input onChange={(e)=>{
                                                setCurrentTarif("video");
                                            }} checked={currentTarif==="video"} name='tarif' value="video" id='video' type='radio'/>
                                            <label htmlFor="video">Consultation vidéo: <strong>3000 XOF</strong></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_balise'>
                                            <input onChange={(e)=>{
                                                setCurrentTarif("cabinet");
                                            }} checked={currentTarif==="cabinet"} name='tarif' value="cabinet" id='cabinet' type='radio'/>
                                            <label htmlFor="cabinet">Consultation au cabinet: <strong>2500 XOF</strong></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_balise'>
                                            <input onChange={(e)=>{
                                                setCurrentTarif("domicile");
                                            }} checked={currentTarif==="domicile"} name='tarif' value="domicile" id='domicile' type='radio'/>
                                            <label htmlFor="domicile">Consultation à domicile: <strong>4000 XOF</strong></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nom</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.nom} name='lastName' required placeholder='nom' type='text'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        nom: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Votre nom</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Prénom</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.prenom} name='firstName' required placeholder='Prénom' type='text'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        prenom: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Votre prénom</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Numéro de téléphone</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.numero_telephone} name='phoneNumber' placeholder='' type='tel'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        numero_telephone: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Votre numéro</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Email</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.email} required name='email' placeholder='' type='email'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        email: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Votre email</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Sexe à l'état civil</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input checked={dataForRequest.genre==="Féminin"} name='sex' value="Féminin" id='female' type='radio'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        genre: e.target.value,
                                                    })
                                                }}
                                            />
                                            <label htmlFor="female">Féminin</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_balise'>
                                            <input checked={dataForRequest.genre==="Masculin"} name='sex' value="Masculin" id='mal' type='radio'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        genre: e.target.value,
                                                    })
                                                }}
                                            />
                                            <label htmlFor="mal">Masculin</label>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Date de naissance</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.date_naissance} name='borne_month' required placeholder='dd/mm/yy' type='date'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        date_naissance: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            {/* <span>Âge: <strong></strong></span> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Adresse</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={dataForRequest.adresse} name='address' placeholder='' type='text'
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        adresse: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Motif de la consultation</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <textarea value={dataForRequest.motif_consultation} name="motif_consultation" required
                                                onChange={(e)=>{
                                                    setDataForRequest({
                                                        ...dataForRequest,
                                                        motif_consultation: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="flex">
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Préférences de date et d'heure</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <div className='flex datePickerContent'>
                                                <div className='datePickerContent'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateCalendar']}>
                                                            <DateCalendar
                                                                referenceDate={dayjs(dataForRequest.date_rdv!==""?dataForRequest.date_rdv:new Date())}
                                                                views={['year', 'month', 'day']}
                                                                // style={{width: "100%"}}
                                                                onChange={(e)=>{
                                                                    setDataForRequest({
                                                                        ...dataForRequest,
                                                                        date_rdv: new Date(e.$d).toISOString(),
                                                                    })
                                                                    // setRDVDate(new Date(e.$d).toISOString());
                                                                }}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <div className='form_input'>
                                                    <div className='form_input_title'>
                                                        <span></span>
                                                    </div>
                                                    <div className='form_input_balise'>
                                                        <input value={dataForRequest.heure_debut} name='heure_debut' placeholder='' type='time'
                                                            onChange={(e)=>{
                                                                setDataForRequest({
                                                                    ...dataForRequest,
                                                                    heure_debut: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='form_input_subText'>
                                                        <span>Heure de préférence</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='login_content_form_button'>
                                    <button>
                                        Soumettre
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }
            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDoctorView);