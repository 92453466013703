import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import '../styles/dashboard.appointment.style.scss';

// import { ReactSearchAutocomplete } from 'react-search-autocomplete';

// import loader from '../assets/gif/loader.gif';

import AuthRequest from '../helpers/auth.requests.helper';

// import Verifier from '../helpers/verifier.helper';
// import UserRequest from '../helpers/user.requests.helper';
// import UserRequest from '../helpers/user.requests.helper';

// const VERF = new Verifier();

// const USR = new UserRequest();

const Auth = new AuthRequest();
// const USR = new UserRequest();

function DashboardGenerateUsers( props ) {
    const LIST_ = [
        {
            "nom": "Garcia",
            "prenom": "Hugo",
            "username": "hugo.garcia",
            "email": "hugo.garcia@example.com",
            "password1": "Computer3d48$ab1234CD",
            "password2": "Computer3d48$ab1234CD",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0601234567",
            "specialite": "Chirurgien-dentiste",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Martin",
            "prenom": "Alice",
            "username": "alice.martin",
            "email": "alice.martin@example.com",
            "password1": "Computer3d48$ef5678GH",
            "password2": "Computer3d48$ef5678GH",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0602345678",
            "specialite": "Médecin généraliste",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "David",
            "username": "david.bernard",
            "email": "david.bernard@example.com",
            "password1": "Computer3d48$ij9012KL",
            "password2": "Computer3d48$ij9012KL",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0603456789",
            "specialite": "Pédiatre",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Emma",
            "username": "emma.dubois",
            "email": "emma.dubois@example.com",
            "password1": "Computer3d48$mn3456OP",
            "password2": "Computer3d48$mn3456OP",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0604567890",
            "specialite": "Ophtalmologue",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Florent",
            "username": "florent.durand",
            "email": "florent.durand@example.com",
            "password1": "Computer3d48$qr7890ST",
            "password2": "Computer3d48$qr7890ST",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0605678901",
            "specialite": "Dermatologue et vénérologue",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Gabrielle",
            "username": "gabrielle.lefebvre",
            "email": "gabrielle.lefebvre@example.com",
            "password1": "Computer3d48$uv1234WX",
            "password2": "Computer3d48$uv1234WX",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0606789012",
            "specialite": "Ostéopathe",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Julien",
            "username": "julien.leroy",
            "email": "julien.leroy@example.com",
            "password1": "Computer3d48$yz5678AB",
            "password2": "Computer3d48$yz5678AB",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0607890123",
            "specialite": "Masseur-kinésithérapeute",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Isabelle",
            "username": "isabelle.roux",
            "email": "isabelle.roux@example.com",
            "password1": "Computer3d48$cd9012EF",
            "password2": "Computer3d48$cd9012EF",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0608901234",
            "specialite": "Opticien-lunetier",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Thomas",
            "username": "thomas.moreau",
            "email": "thomas.moreau@example.com",
            "password1": "Computer3d48$gh3456IJ",
            "password2": "Computer3d48$gh3456IJ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0609012345",
            "specialite": "Pédicure-podologue",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Karine",
            "username": "karine.simon",
            "email": "karine.simon@example.com",
            "password1": "Computer3d48$kl7890MN",
            "password2": "Computer3d48$kl7890MN",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0610123456",
            "specialite": "Sage-femme",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Paul",
            "username": "paul.laurent",
            "email": "paul.laurent@example.com",
            "password1": "Computer3d48$op1234QR",
            "password2": "Computer3d48$op1234QR",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0611234567",
            "specialite": "ORL",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Benjamin",
            "username": "benjamin.garcia",
            "email": "benjamin.garcia@example.com",
            "password1": "Computer3d48$st5678UV",
            "password2": "Computer3d48$st5678UV",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0612345678",
            "specialite": "Allergologue",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Charlotte",
            "username": "charlotte.bernard",
            "email": "charlotte.bernard@example.com",
            "password1": "Computer3d48$wx9012YZ",
            "password2": "Computer3d48$wx9012YZ",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0613456789",
            "specialite": "Chirurgien urologue",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Romain",
            "username": "romain.dubois",
            "email": "romain.dubois@example.com",
            "password1": "Computer3d48$ab3456CD",
            "password2": "Computer3d48$ab3456CD",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0614567890",
            "specialite": "Rhumatologue",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Isabelle",
            "username": "isabelle.durand",
            "email": "isabelle.durand@example.com",
            "password1": "Computer3d48$ef7890GH",
            "password2": "Computer3d48$ef7890GH",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0615678901",
            "specialite": "Stomatologue",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Hugo",
            "username": "hugo.lefebvre",
            "email": "hugo.lefebvre@example.com",
            "password1": "Computer3d48$ij1234KL",
            "password2": "Computer3d48$ij1234KL",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0616789012",
            "specialite": "Endocrinologue",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Emma",
            "username": "emma.leroy",
            "email": "emma.leroy@example.com",
            "password1": "Computer3d48$mn5678OP",
            "password2": "Computer3d48$mn5678OP",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0617890123",
            "specialite": "Chirurgien orthopédiste et traumatologue",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Gabrielle",
            "username": "gabrielle.roux",
            "email": "gabrielle.roux@example.com",
            "password1": "Computer3d48$qr9012ST",
            "password2": "Computer3d48$qr9012ST",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0618901234",
            "specialite": "Diététicien",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Julien",
            "username": "julien.moreau",
            "email": "julien.moreau@example.com",
            "password1": "Computer3d48$uv3456WX",
            "password2": "Computer3d48$uv3456WX",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0619012345",
            "specialite": "Psychologue",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Nicolas",
            "username": "nicolas.simon",
            "email": "nicolas.simon@example.com",
            "password1": "Computer3d48$yz5678AB",
            "password2": "Computer3d48$yz5678AB",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0620123456",
            "specialite": "Neurologue",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Alice",
            "username": "alice.laurent",
            "email": "alice.laurent@example.com",
            "password1": "Computer3d48$cd7890EF",
            "password2": "Computer3d48$cd7890EF",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0621234567",
            "specialite": "Psychiatre",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "David",
            "username": "david.garcia",
            "email": "david.garcia@example.com",
            "password1": "Computer3d48$gh1234IJ",
            "password2": "Computer3d48$gh1234IJ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0622345678",
            "specialite": "Radiologue",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Benjamin",
            "username": "benjamin.bernard",
            "email": "benjamin.bernard@example.com",
            "password1": "Computer3d48$kl3456MN",
            "password2": "Computer3d48$kl3456MN",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0623456789",
            "specialite": "Cardiologue",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Charlotte",
            "username": "charlotte.dubois",
            "email": "charlotte.dubois@example.com",
            "password1": "Computer3d48$op7890QR",
            "password2": "Computer3d48$op7890QR",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0624567890",
            "specialite": "Gastro-entérologue et hépatologue",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Romain",
            "username": "romain.durand",
            "email": "romain.durand@example.com",
            "password1": "Computer3d48$st1234UV",
            "password2": "Computer3d48$st1234UV",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0625678901",
            "specialite": "Acupuncteur",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Florent",
            "username": "florent.lefebvre",
            "email": "florent.lefebvre@example.com",
            "password1": "Computer3d48$wx3456YZ",
            "password2": "Computer3d48$wx3456YZ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0626789012",
            "specialite": "Addictologue",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Gabrielle",
            "username": "gabrielle.leroy",
            "email": "gabrielle.leroy@example.com",
            "password1": "Computer3d48$ab5678CD",
            "password2": "Computer3d48$ab5678CD",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0627890123",
            "specialite": "Adjoint chirurgien-dentiste",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Hugo",
            "username": "hugo.roux",
            "email": "hugo.roux@example.com",
            "password1": "Computer3d48$ef9012GH",
            "password2": "Computer3d48$ef9012GH",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0628901234",
            "specialite": "Adjoint médecin",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Isabelle",
            "username": "isabelle.moreau",
            "email": "isabelle.moreau@example.com",
            "password1": "Computer3d48$ij3456KL",
            "password2": "Computer3d48$ij3456KL",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0629012345",
            "specialite": "Anatomo-cyto-pathologiste",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Julien",
            "username": "julien.simon",
            "email": "julien.simon@example.com",
            "password1": "Computer3d48$mn5678OP",
            "password2": "Computer3d48$mn5678OP",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0630123456",
            "specialite": "Andrologue",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Karine",
            "username": "karine.laurent",
            "email": "karine.laurent@example.com",
            "password1": "Computer3d48$qr7890ST",
            "password2": "Computer3d48$qr7890ST",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0631234567",
            "specialite": "Anesthésiste",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Paul",
            "username": "paul.garcia",
            "email": "paul.garcia@example.com",
            "password1": "Computer3d48$uv1234WX",
            "password2": "Computer3d48$uv1234WX",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0632345678",
            "specialite": "Angiologue",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Alice",
            "username": "alice.bernard",
            "email": "alice.bernard@example.com",
            "password1": "Computer3d48$yz3456AB",
            "password2": "Computer3d48$yz3456AB",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0633456789",
            "specialite": "Assistant de service social",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "David",
            "username": "david.dubois",
            "email": "david.dubois@example.com",
            "password1": "Computer3d48$cd5678EF",
            "password2": "Computer3d48$cd5678EF",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0634567890",
            "specialite": "Audioprothésiste",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Florent",
            "username": "florent.durand",
            "email": "florent.durand@example.com",
            "password1": "Computer3d48$gh7890IJ",
            "password2": "Computer3d48$gh7890IJ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0635678901",
            "specialite": "Biologiste médical",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Emma",
            "username": "emma.lefebvre",
            "email": "emma.lefebvre@example.com",
            "password1": "Computer3d48$kl1234MN",
            "password2": "Computer3d48$kl1234MN",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0636789012",
            "specialite": "Chirurgien-dentiste",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Romain",
            "username": "romain.leroy",
            "email": "romain.leroy@example.com",
            "password1": "Computer3d48$op3456QR",
            "password2": "Computer3d48$op3456QR",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0637890123",
            "specialite": "Chirurgien infantile",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Charlotte",
            "username": "charlotte.roux",
            "email": "charlotte.roux@example.com",
            "password1": "Computer3d48$st5678UV",
            "password2": "Computer3d48$st5678UV",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0638901234",
            "specialite": "Chirurgien maxillo-facial et stomatologue",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Gabrielle",
            "username": "gabrielle.moreau",
            "email": "gabrielle.moreau@example.com",
            "password1": "Computer3d48$wx9012YZ",
            "password2": "Computer3d48$wx9012YZ",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0639012345",
            "specialite": "Chirurgien pédiatrique",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Hugo",
            "username": "hugo.simon",
            "email": "hugo.simon@example.com",
            "password1": "Computer3d48$ab3456CD",
            "password2": "Computer3d48$ab3456CD",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0640123456",
            "specialite": "Chirurgien plastique reconstructeur et esthétique",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Isabelle",
            "username": "isabelle.laurent",
            "email": "isabelle.laurent@example.com",
            "password1": "Computer3d48$ef7890GH",
            "password2": "Computer3d48$ef7890GH",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0641234567",
            "specialite": "Chirurgien thoracique et cardio-vasculaire",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Julien",
            "username": "julien.garcia",
            "email": "julien.garcia@example.com",
            "password1": "Computer3d48$ij1234KL",
            "password2": "Computer3d48$ij1234KL",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0642345678",
            "specialite": "Chirurgien urologue",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Emma",
            "username": "emma.bernard",
            "email": "emma.bernard@example.com",
            "password1": "Computer3d48$mn3456OP",
            "password2": "Computer3d48$mn3456OP",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0643456789",
            "specialite": "Dentiste",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Thomas",
            "username": "thomas.dubois",
            "email": "thomas.dubois@example.com",
            "password1": "Computer3d48$qr5678ST",
            "password2": "Computer3d48$qr5678ST",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0644567890",
            "specialite": "Dermatologue",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Karine",
            "username": "karine.durand",
            "email": "karine.durand@example.com",
            "password1": "Computer3d48$uv7890WX",
            "password2": "Computer3d48$uv7890WX",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0645678901",
            "specialite": "Endocrinologue",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Romain",
            "username": "romain.lefebvre",
            "email": "romain.lefebvre@example.com",
            "password1": "Computer3d48$yz1234AB",
            "password2": "Computer3d48$yz1234AB",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0646789012",
            "specialite": "Gastro-entérologue",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Charlotte",
            "username": "charlotte.leroy",
            "email": "charlotte.leroy@example.com",
            "password1": "Computer3d48$cd5678EF",
            "password2": "Computer3d48$cd5678EF",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0647890123",
            "specialite": "Généticien",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "David",
            "username": "david.roux",
            "email": "david.roux@example.com",
            "password1": "Computer3d48$gh9012IJ",
            "password2": "Computer3d48$gh9012IJ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0648901234",
            "specialite": "Gériatre",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Benjamin",
            "username": "benjamin.moreau",
            "email": "benjamin.moreau@example.com",
            "password1": "Computer3d48$kl3456MN",
            "password2": "Computer3d48$kl3456MN",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0649012345",
            "specialite": "Gynécologue-obstétricien",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Alice",
            "username": "alice.simon",
            "email": "alice.simon@example.com",
            "password1": "Computer3d48$op5678QR",
            "password2": "Computer3d48$op5678QR",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0650123456",
            "specialite": "Hématologue",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Thomas",
            "username": "thomas.laurent",
            "email": "thomas.laurent@example.com",
            "password1": "Computer3d48$st7890UV",
            "password2": "Computer3d48$st7890UV",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0651234567",
            "specialite": "Infirmier",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Emma",
            "username": "emma.garcia",
            "email": "emma.garcia@example.com",
            "password1": "Computer3d48$wx1234YZ",
            "password2": "Computer3d48$wx1234YZ",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0652345678",
            "specialite": "Kiné",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Julien",
            "username": "julien.bernard",
            "email": "julien.bernard@example.com",
            "password1": "Computer3d48$ab3456CD",
            "password2": "Computer3d48$ab3456CD",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0653456789",
            "specialite": "Médecin généraliste",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Karine",
            "username": "karine.dubois",
            "email": "karine.dubois@example.com",
            "password1": "Computer3d48$ef5678GH",
            "password2": "Computer3d48$ef5678GH",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0654567890",
            "specialite": "Médecin hygiéniste",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Thomas",
            "username": "thomas.durand",
            "email": "thomas.durand@example.com",
            "password1": "Computer3d48$ij7890KL",
            "password2": "Computer3d48$ij7890KL",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0655678901",
            "specialite": "Médecin du travail",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Benjamin",
            "username": "benjamin.lefebvre",
            "email": "benjamin.lefebvre@example.com",
            "password1": "Computer3d48$mn1234OP",
            "password2": "Computer3d48$mn1234OP",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0656789012",
            "specialite": "Masseur-kinésithérapeute",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Emma",
            "username": "emma.leroy",
            "email": "emma.leroy@example.com",
            "password1": "Computer3d48$qr3456ST",
            "password2": "Computer3d48$qr3456ST",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0657890123",
            "specialite": "Microbiologiste",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Thomas",
            "username": "thomas.roux",
            "email": "thomas.roux@example.com",
            "password1": "Computer3d48$uv5678WX",
            "password2": "Computer3d48$uv5678WX",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0658901234",
            "specialite": "Médecin scolaire",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "David",
            "username": "david.moreau",
            "email": "david.moreau@example.com",
            "password1": "Computer3d48$yz7890AB",
            "password2": "Computer3d48$yz7890AB",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0659012345",
            "specialite": "Neurochirurgien",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Isabelle",
            "username": "isabelle.simon",
            "email": "isabelle.simon@example.com",
            "password1": "Computer3d48$cd1234EF",
            "password2": "Computer3d48$cd1234EF",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0660123456",
            "specialite": "Néphrologue",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Julien",
            "username": "julien.laurent",
            "email": "julien.laurent@example.com",
            "password1": "Computer3d48$gh5678IJ",
            "password2": "Computer3d48$gh5678IJ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0661234567",
            "specialite": "Neuropsychiatre",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Thomas",
            "username": "thomas.garcia",
            "email": "thomas.garcia@example.com",
            "password1": "Computer3d48$kl7890MN",
            "password2": "Computer3d48$kl7890MN",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0662345678",
            "specialite": "Nutritionniste",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Gabrielle",
            "username": "gabrielle.bernard",
            "email": "gabrielle.bernard@example.com",
            "password1": "Computer3d48$op1234QR",
            "password2": "Computer3d48$op1234QR",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0663456789",
            "specialite": "Oncologue médical",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Julien",
            "username": "julien.dubois",
            "email": "julien.dubois@example.com",
            "password1": "Computer3d48$st3456UV",
            "password2": "Computer3d48$st3456UV",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0664567890",
            "specialite": "Oncologue radiothérapeute",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Alice",
            "username": "alice.durand",
            "email": "alice.durand@example.com",
            "password1": "Computer3d48$wx5678YZ",
            "password2": "Computer3d48$wx5678YZ",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0665678901",
            "specialite": "Ophtalmologiste",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Charlotte",
            "username": "charlotte.lefebvre",
            "email": "charlotte.lefebvre@example.com",
            "password1": "Computer3d48$ab9012CD",
            "password2": "Computer3d48$ab9012CD",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0666789012",
            "specialite": "Orthopédiste",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "David",
            "username": "david.leroy",
            "email": "david.leroy@example.com",
            "password1": "Computer3d48$ef3456GH",
            "password2": "Computer3d48$ef3456GH",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0667890123",
            "specialite": "Ostéopathe",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Julien",
            "username": "julien.roux",
            "email": "julien.roux@example.com",
            "password1": "Computer3d48$ij5678KL",
            "password2": "Computer3d48$ij5678KL",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0668901234",
            "specialite": "Pédiatre",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Charlotte",
            "username": "charlotte.moreau",
            "email": "charlotte.moreau@example.com",
            "password1": "Computer3d48$mn7890OP",
            "password2": "Computer3d48$mn7890OP",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0669012345",
            "specialite": "Pharmacien",
            "carte_de_medecin": "9012345678"
        },
        {
            "nom": "Simon",
            "prenom": "Benjamin",
            "username": "benjamin.simon",
            "email": "benjamin.simon@example.com",
            "password1": "Computer3d48$qr1234ST",
            "password2": "Computer3d48$qr1234ST",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0670123456",
            "specialite": "Physiatre",
            "carte_de_medecin": "0123456789"
        },
        {
            "nom": "Laurent",
            "prenom": "Julien",
            "username": "julien.laurent",
            "email": "julien.laurent@example.com",
            "password1": "Computer3d48$uv3456WX",
            "password2": "Computer3d48$uv3456WX",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0671234567",
            "specialite": "Psychiatre",
            "carte_de_medecin": "1234567890"
        },
        {
            "nom": "Garcia",
            "prenom": "Karine",
            "username": "karine.garcia",
            "email": "karine.garcia@example.com",
            "password1": "Computer3d48$yz5678AB",
            "password2": "Computer3d48$yz5678AB",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0672345678",
            "specialite": "Radiologue",
            "carte_de_medecin": "2345678901"
        },
        {
            "nom": "Bernard",
            "prenom": "Romain",
            "username": "romain.bernard",
            "email": "romain.bernard@example.com",
            "password1": "Computer3d48$cd7890EF",
            "password2": "Computer3d48$cd7890EF",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0673456789",
            "specialite": "Rhumatologue",
            "carte_de_medecin": "3456789012"
        },
        {
            "nom": "Dubois",
            "prenom": "Alice",
            "username": "alice.dubois",
            "email": "alice.dubois@example.com",
            "password1": "Computer3d48$gh1234IJ",
            "password2": "Computer3d48$gh1234IJ",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0674567890",
            "specialite": "Médecin du sport",
            "carte_de_medecin": "4567890123"
        },
        {
            "nom": "Durand",
            "prenom": "Julien",
            "username": "julien.durand",
            "email": "julien.durand@example.com",
            "password1": "Computer3d48$kl5678MN",
            "password2": "Computer3d48$kl5678MN",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0675678901",
            "specialite": "Spécialiste en médecine d'urgence",
            "carte_de_medecin": "5678901234"
        },
        {
            "nom": "Lefebvre",
            "prenom": "Gabrielle",
            "username": "gabrielle.lefebvre",
            "email": "gabrielle.lefebvre@example.com",
            "password1": "Computer3d48$op7890QR",
            "password2": "Computer3d48$op7890QR",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0676789012",
            "specialite": "Spécialiste en médecine interne",
            "carte_de_medecin": "6789012345"
        },
        {
            "nom": "Leroy",
            "prenom": "Benjamin",
            "username": "benjamin.leroy",
            "email": "benjamin.leroy@example.com",
            "password1": "Computer3d48$st1234UV",
            "password2": "Computer3d48$st1234UV",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0677890123",
            "specialite": "Urologue",
            "carte_de_medecin": "7890123456"
        },
        {
            "nom": "Roux",
            "prenom": "Julien",
            "username": "julien.roux",
            "email": "julien.roux@example.com",
            "password1": "Computer3d48$wx3456YZ",
            "password2": "Computer3d48$wx3456YZ",
            "role": "Medecin",
            "sexe": "H",
            "civilite": "homme",
            "numero_telephone": "0678901234",
            "specialite": "Vétérinaire",
            "carte_de_medecin": "8901234567"
        },
        {
            "nom": "Moreau",
            "prenom": "Karine",
            "username": "karine.moreau",
            "email": "karine.moreau@example.com",
            "password1": "Computer3d48$ab1234CD",
            "password2": "Computer3d48$ab1234CD",
            "role": "Medecin",
            "sexe": "F",
            "civilite": "femme",
            "numero_telephone": "0679012345",
            "specialite": "Virologue",
            "carte_de_medecin": "9012345678"
        }
    ];

    const [user, setUser] = useState(null);
    const [index_, setIndex_] = useState(0);
    const [users, setUsers] = useState(["Medecins"]);

    function addUser(index) {
        // console.log(index);
        Auth.addUser(user.ID, LIST_[index]).then((response) => {
            
            setUsers((prev)=>{
                let newt = prev;
                newt.push(LIST_[index].nom + " " + LIST_[index].prenom + ": " + LIST_[index].specialite);
                return newt;
            });
            if(index<LIST_.length) {
                console.log(response);
                setIndex_((prev)=>{
                    setTimeout(()=>{
                        if(response.data.success === true) {
                            addUser(prev+1);
                        }
                    },3000);
                    return prev+1;
                });
                
            }
        }).catch((e) => {
            setUsers((prev)=>{
                let newt = prev;
                newt.push(LIST_[index].nom + " " + LIST_[index].prenom + ": " + LIST_[index].specialite);
                return newt;
            });
            console.log(users);
            setIndex_((prev)=>{
                setTimeout(()=>{
                    if(index<LIST_.length) {
                        addUser(prev+1);
                    }
                },3000);
                return prev+1;
            });
            
        });
    }
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("ilera")));
    },[]);

        
    return (
        <div className='prescription'>
            <button
                onClick={(e) => {
                    addUser(index_);
                }}
            >
                Générer
            </button>
            <div className="">
                {
                    users.map((elt, index) => (
                        <div key={elt}>
                            <span>
                                {elt}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGenerateUsers);