import React, {useState} from 'react';
import { connect } from 'react-redux';

import '../styles/login.screen.style.scss';

/**Imortation des helpers */
import AuthRequest from '../helpers/auth.requests.helper';

/**Importation des images */
import ilera from '../assets/images/ilèra_logo.png'

import loader from '../assets/gif/loader.gif';

import vid1 from '../assets/gif/consultation.mp4';

import emailjs from '@emailjs/browser';

const Auth = new AuthRequest();

function ForgotPasswordView( props ) {
    const [isLoading, setIsLoading] = useState(false);

    function SendResetCode() {
        setIsLoading(true);
        let params = {
            to_email: document.forms["resetPassword"]["user_email"].value,
            message: "Code: 32425",
            from_name: "Ilera"
        };
        sendEmail(params, ()=>{
            setIsLoading(false);
            // window.location.href = "/change-password/" + encodeURIComponent(response.data.reset_link);
        });
        // console.log(data);
        // // window.location.href = "/change-password";
        // Auth.sendResetPasswordCode(data).then((response) => {
        //     setIsLoading(false);
        //     // let user = response.data;
        //     // console.log(response.data);
        //     if(response.data.success === true) {
        //         alert(response.data.message);
        //         window.location.href = "/change-password/" + encodeURIComponent(response.data.reset_link);
        //     }
        // }).catch((e) => {
        //     setIsLoading(false);
        //     if(e.response.status === 404) {
        //         alert("Email invalide");
        //     }
        // });
    }

    function sendEmail(templateParams, callback){
        emailjs
          .send('service_xfowemo', 'template_5r6kdnc', templateParams, "WexlQxMspaHE5eVW8").then(
            () => {
              callback();
            },
            (error) => {
                setIsLoading(false);
              console.log('FAILED...', error);
            },
        );
    };

    return (
        <div className='login'>
            <div className='login_left'>
                <video src={vid1} loop autoPlay={true}></video>
                <div className='login_left_logo'>
                    <img src={ilera} alt="" />
                </div>
                {/* <div>
                    <span>
                        PATIENT
                    </span>
                </div> */}
            </div>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Mot de passe oublié ?
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Cliquez pour reçevoir le code de réinitialisation par email</span> 
                            {/* <img src={ilera} alt='ilera'/> */}
                        </div>
                    </div>
                </div>

                <form name="resetPassword" action='#' method='POST' className='login_content_form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        SendResetCode(e.target);
                    }}
                >
                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Email du compte</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="user_email" required placeholder="votre nom d'utilisateur" type='email'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Saisissez l'email de votre compte ici</span>
                        </div>
                    </div>

                    <textarea name="message" value={"Votre code est 345321"} disabled style={{visibility: "hidden", transform: "scale(0)", height: "0px"}}/>
                    <input type="text" name="user_name" value={" "} disabled style={{visibility: "hidden", transform: "scale(0)", height: "0px"}}/>
                    <div className='login_content_form_button'>
                        <button>
                            Envoyer le code
                        </button>
                    </div>

                    <div className='login_content_form_button returnHome'>
                        <a href='/' style={{fontSize: "1em"}}>
                            Retourner à l'accueil
                        </a>
                    </div>
                </form>
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);