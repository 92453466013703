import React, { useState, useEffect }from 'react';
import { connect } from 'react-redux';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import '../styles/login.screen.style.scss';

/**Importation des images */
import ilera from '../assets/images/ilèra_logo.png'
import loader from '../assets/gif/loader.gif';
import vid1 from '../assets/gif/consultation.mp4';
import vid2 from '../assets/gif/team.mp4';

/**Importation des screens pour les differentes etapes */

import Verifier from '../helpers/verifier.helper';
import UserRequest from '../helpers/user.requests.helper';


// import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';

import ReactCountryFlagsSelect from 'react-country-flags-select';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr'); 

const VERF = new Verifier();

const USR = new UserRequest();

function RegistrationView( props ) {

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [isLoading, setIsLoading] = useState(false);
    // const [viewPolitic, setViewPolitic] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [born, setBorn] = useState("");
    const [error, setError] = useState('');
    const [civil, setCivil] = useState("");
    const [isConsent, setIsConsent] = useState(false);
    const [isConsentNotice, setIsConsentNotice] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [country, setCountry] = useState();

    const [password_verification_results, setpassword_verification_results] = useState(
        {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
            like_personal_information: true,
            isOk: false,
            isConfirmed: false,
        }
    );

    const [step, setStep] = useState(0);

    const handleDateChange = (newValue) => {
        console.log(newValue);
        const age = dayjs().diff(newValue, 'year');
        if (age < 18 || age > 200) {
            setError('Vous devez avoir un âge valide entre 18ans et 200ans.');
        } else {
            setError('');
            setBorn(newValue);
        }
    };

    // const formatDate = (date) => {
    //     return date ? dayjs(date).format('DD/MM/YYYY') : '';
    // };

    function addUser() {
        setIsLoading(true);
        let bn = new Date(born.$d);
        
        let user = {
            "civilite": civil,
            "sexe": civil==="Mr" ? "M" : "F",
            "nom": lastName,
            "prenom": firstName,
            "adresse": "....",
            "role": "utilisateur_standard",
            "pays": JSON.stringify(country),
            "code_promo": "-1",
            "email" : email,
            "username" : email,
            "password1" : password,
            "password2" : confirmPassword,
            "date_naissance": bn.getFullYear() + "-" + bn.getUTCMonth() + "-" + bn.getUTCDay(),
            "numero_telephone": phoneNumber,
        };
        console.log(user);
        USR.add(user).then((response) => {
            setIsLoading(false);
            let res = response.data;
            console.log(response);
            // localStorage.setItem("ilera", JSON.stringify(user));
            if(res.success === true) {
                // alert(res.message);
                // window.location.href = "/login";
                setStep(3);
            } else {
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            console.log(e.response.data);
            if(e.response.data.errors) {
                if(e.response.data.errors.user) {
                    if(e.response.data.errors.user.username) {
                        if(e.response.data.errors.user.username[0] === "A user with that username already exists.") {
                            alert("Un utilisateur avec cet email existe déjà.");
                        }

                        else {
                            alert(e.response.data.errors.user.username[0]);
                        }
                    }
                }
            }
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setCountry({countryCode: "BJ",label: "Benin"});
    },[]);

    return (
        <div className='login'>
            <div className='login_left'>
                <video src={vid1} loop autoPlay={true}></video>
                <div className='login_left_logo'>
                    <img src={ilera} alt="" />
                </div>
                {/* <div>
                    <span>
                        PATIENT
                    </span>
                </div> */}
            </div>
            <div className='login_content'>
                {
                    step > 0 && step < 3?
                        <div className="login_content_head_back">
                            <button
                                onClick={(e)=>{
                                    setStep((prev)=>{
                                        return (prev-1)
                                    });
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                </svg>
                            </button>
                        </div>
                    : null
                }
                {
                    step<3 &&
                        <div className='login_content_head'>
                            
                            <div className='login_content_head_logo'>
                                <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                    <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                                </svg>
                            </div>
                            <div className='login_content_head_title'>
                                <div className='login_content_head_title_top'>
                                    Créer un compte
                                </div>
                                <div className='login_content_head_title_bottom'>
                                    <span>Veuillez saisir vos informations pour rejoindre ilera</span> 
                                    {/* <img src={ilera} alt='ilera'/> */}
                                </div>
                            </div>
                        </div>
                }
                <div>
                    <div className="steps_s">
                        <div className={'step_s ' + (step>=0? "valid" : "")}></div>
                        <div className={'step_s ' + (step>=1? "valid" : "")}></div>
                        <div className={'step_s ' + (step>=2? "valid" : "")}></div>
                        <div className={'step_s ' + (step>=3? "valid" : "")}></div>
                    </div>
                </div>

                {
                    step === 0 ? 
                        <form action='#' method='POST' className='login_content_form' name='registrationForm'
                            onSubmit={(e) => {
                                e.preventDefault();
                                const age = dayjs().diff(born, 'year');
                                if (age >= 18) {
                                    setError('');
                                    setStep(1); 
                                } 
                            }}
                        >
            
                            <div className='login_content_form_input'>
                                <div>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        label="Civilité"
                                        defaultValue=""
                                        helperText=""
                                        variant="standard"
                                        required
                                        fullWidth
                                        value={civil}
                                        onChange={(e) => setCivil(e.target.value)}
                                    >
                                        <MenuItem value={"Mr"}>
                                            Monsieur
                                        </MenuItem>
                                        <MenuItem value={"Mme"}>
                                            Madame
                                        </MenuItem>
                                    </TextField>
                                </div>
                                <div style={{marginTop: "30px", display: "flex" }}>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Prénom"
                                        required
                                        fullWidth
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                            </InputAdornment>
                                        ),
                                        }}
                                        variant="standard"
                                    />
                                    <div style={{marginLeft: "5px", marginRight: "5px"}}></div>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Nom"
                                        required
                                        fullWidth
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                            </InputAdornment>
                                        ),
                                        }}
                                        variant="standard"
                                    />
                                </div>

                                <div style={{marginTop: "30px", display: "flex"}}>
                                    <div className='' style={{width: "100%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={"JJ/MM/AAAA"} adapterLocale="fr">
                                            <DateField
                                                required
                                                fullWidth
                                                label="Date de naissance"
                                                value={born}
                                                onChange={handleDateChange}
                                                variant='standard'
                                                error={Boolean(error)}
                                                helperText={error}
                                                format="DD/MM/YYYY"
                                                lang='fr'
                                                aria-placeholder='JJ/MM/AAAA'
                                                aria-controls='JJ/MM/AAAA'
                                            />
                                            
                                        </LocalizationProvider>
                                        <div>
                                            <span style={{fontSize: "0.9em"}}>JJ/MM/AAAA</span>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: "5px", marginRight: "5px"}}></div>
                                    <div className='' style={{width: "100%", marginTop: "-9px"}}>
                                        <div className=''>
                                            <span style={{fontWeight: "normal", fontSize: "0.8em", color: "grey"}}>Pays</span>
                                        </div>
                                        <div className=''>
                                            <ReactCountryFlagsSelect
                                                selected={country}
                                                defaultCountry="BJ"
                                                onSelect={(e)=>{
                                                    // console.log(e);
                                                    setCountry(e);
                                                }}
                                                fullWidth
                                                searchable
                                                clearIcon={false}
                                                variant='standard'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                               

                                <div style={{marginTop: "0px", display: "flex" }}></div>
                            </div>

                            
            
                            <div className='login_content_form_button'>
                                <button type='submit' style={{opacity: (Boolean(error) || born==="") ? 0.2 : 1}} disabled={Boolean(error)}>
                                    CONTINUER
                                </button>
                                <a href='/login'>
                                    Se connecter
                                </a>
                            </div>
            
                            <div className='login_content_form_button returnHome'>
                                <a href='/'>
                                    Retourner à l'accueil
                                </a>
                            </div>
                        </form>
                    :
                        (
                            step === 1 ?
                                <form action='#' method='POST' className='login_content_form' name='registrationForm'
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setStep(2);
                                    }}
                                >
                                    <div className='login_content_form_input'>
                                        
                                        <div style={{marginTop: "0px", display: "flex" }}>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Adresse email"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </div>
                                        
                                        <div className='login_content_form_input' style={{marginTop: "30px"}}>
                                            {/* <div className='login_content_form_input_title'>
                                                <span>Mot de passe</span>
                                            </div> */}
                                            <div className=''>
                                                <FormControl fullWidth variant="standard">
                                                    <InputLabel style={{marginLeft: "10px"}} htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        name='password'
                                                        label="Password"
                                                        value={password}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            setPassword(value);
                                                            let password_verification_results_ = {
                                                                number: false,
                                                                string: false,
                                                                special_character: false,
                                                                minimum_character: false,
                                                                like_personal_information: true,
                                                                isOk: false,
                                                                isConfirmed: false,
                                                            };
                                                            let result = VERF._password(value, 8);
    
                                                            password_verification_results_.minimum_character = result.minimum_character;
                                                            password_verification_results_.special_character = result.special_character;
                                                            password_verification_results_.string = result.string;
                                                            password_verification_results_.number = result.number;
    
                                                            
                                                            if( 
                                                                ( born.length > 0 && value.toLowerCase().split(born.toLowerCase()).length > 1 )||
                                                                ( email.length > 0 && value.toLowerCase().split(email.toLowerCase()).length > 1 )||
                                                                ( phoneNumber.length > 0 && value.toLowerCase().split(phoneNumber.toLowerCase()).length > 1 )
                                                                // ( document.forms["registrationForm"]["mail"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["mail"].value.toLowerCase()).length > 1 )
                                                            ) {
                                                                password_verification_results_.like_personal_information = true;
                                                            }else {
                                                                password_verification_results_.like_personal_information = false;
                                                            }
    
                                                            if( document.forms["registrationForm"]["confirmPassword"].value.length > 0 &&  document.forms["registrationForm"]["confirmPassword"].value === value ) {
                                                                password_verification_results_.isConfirmed = true;
                                                            }else {
                                                                password_verification_results_.isConfirmed = false;
                                                            }
    
                                                            setpassword_verification_results(password_verification_results_);
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='login_content_form_input_verf'>
                                                <span
                                                    style={{
                                                        color: password_verification_results.like_personal_information ? "#d11919" : "#5dc269",
                                                    }}
                                                >- Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.</span>
                                                
                                                <span
                                                    style={{
                                                        color: !password_verification_results.minimum_character ? "#d11919" : "#5dc269",
                                                    }}
                                                >- Votre mot de passe doit contenir au minimum 8 caractères.</span>
                                                
                                                <span
                                                    style={{
                                                        color: !password_verification_results.number ? "#d11919" : "#5dc269",
                                                    }}
                                                >- Votre mot de passe doit contenir au moins un chiffre.</span>
                                                
                                                <span
                                                    style={{
                                                        color: !password_verification_results.string ? "#d11919" : "#5dc269",
                                                    }}
                                                >- Votre mot de passe doit contenir au moins une lettre.</span>
                                                
                                                <span
                                                    style={{
                                                        color: !password_verification_results.special_character ? "#d11919" : "#5dc269",
                                                    }}
                                                >- Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « # », « % », « & », « $ » et « _ ».</span>
                                            </div>
                                        </div>

                                        <div className='login_content_form_input'>
                                            {/* <div className='login_content_form_input_title'>
                                                <span></span>
                                            </div> */}
                                            <div className=''>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel style={{marginLeft: "10px"}} htmlFor="outlined-adornment-password">Confirmez votre mot de passe</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="Password"
                                                    value={confirmPassword}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        setConfirmPassword(value);
                                                        if( document.forms["registrationForm"]["password"].value.length > 0 &&  document.forms["registrationForm"]["password"].value === value ) {
                                                            setpassword_verification_results({
                                                                ...password_verification_results,
                                                                isConfirmed: true,
                                                            })
                                                        }else {
                                                            setpassword_verification_results({
                                                                ...password_verification_results,
                                                                isConfirmed: false,
                                                            })
                                                        }
                                                    }}
                                                    name='confirmPassword'
                                                />
                                            </FormControl>
                                            </div>
                                            <div className='login_content_form_input_verf'>
                                                <span
                                                    style={{
                                                        color: !password_verification_results.isConfirmed ? "#d11919" : "#5dc269",
                                                    }}
                                                >confirmer</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='checkboxGroup'>
                                                <input id='signupNoticeMail'type="checkbox"/>
                                                <label htmlFor='signupNoticeMail'>
                                                    J’accepte de recevoir des conseils pour ma santé
                                                </label>
                                            </div>

                                            <div className='checkboxGroup'>
                                                <input id='signupPolitic'type="checkbox" 
                                                onChange={(e)=>{
                                                    setIsConsentNotice(e.target.checked);
                                                }} checked={isConsentNotice} required />
                                                <label htmlFor='signupPolitic'>
                                                    J’ai lu et j’accepte la <a target={"_blank"} href='/politique'>Politique de Confidentialité ainsi que les Conditions Générales d’Utilisation</a>
                                                </label>
                                            </div>

                                            <div className='checkboxGroup'>
                                                <input id='signupConsent' type="checkbox" onChange={(e)=>{
                                                    setIsConsent(e.target.checked);
                                                }} checked={isConsent} required />
                                                <label htmlFor='signupConsent'>
                                                    J’ai pris connaissance de la <a target={"_blank"} href="/consentement">notice d’information sur l'acte de téléconsultation</a> et j’y consens
                                                </label>
                                            </div>

                                            
                                        </div>

                                        {/* <div style={{marginTop: "0px", display: "flex", fontSize: "0.7em" }}>
                                            <FormGroup>
                                                <FormControlLabel style={{fontSize: "0.7em"}} onChange={(e)=>{
                                                    setIsConsent(e.target.checked);
                                                }} checked={isConsent} control={<Checkbox />} label="J’accepte de recevoir des conseils pour ma santé" />
                                            </FormGroup>
                                        </div> */}

                                        {/* <div style={{marginTop: "0px", display: "flex" }}>
                                            <FormGroup>
                                                <FormControlLabel onChange={(e)=>{
                                                    setIsConsent(e.target.checked);
                                                }} checked={isConsent} required control={<Checkbox />} label=" J’ai pris connaissance de la notice d’information sur l'acte de téléconsultation et j’y consens " />
                                            </FormGroup>
                                        </div>
                                        <div style={{marginTop: "0px", display: "flex" }}>
                                            <FormGroup>
                                                <FormControlLabel onChange={(e)=>{
                                                    setIsConsentNotice(e.target.checked);
                                                }} checked={isConsentNotice} required control={<Checkbox />} label={<>J’ai lu et j’accepte la <a href='/politic'>Politique de Confidentialité ainsi que les Conditions Générales d’Utilisation</a></>} />
                                            </FormGroup>
                                        </div> */}
                                        {/* <div>
                                            <Link href="#" underline="hover" style={{fontSize: "0.8em", marginTop: "-10px"}}>
                                                <span
                                                    onClick={(e)=>{
                                                        setViewPolitic(true);
                                                    }}
                                                >{"Lire les termes, conditions et politiques du site du site"}</span>
                                            </Link>
                                        </div> */}

                                        <div style={{marginTop: "40px", display: "flex" }}></div>
                                    </div>
                    
                                    <div className='login_content_form_button'>
                                        <button>
                                            CONTINUER
                                        </button>
                                        <a href='/login'>
                                            Se connecter
                                        </a>
                                    </div>
                    
                                    <div className='login_content_form_button returnHome'>
                                        <a href='/'>
                                            Retourner à l'accueil
                                        </a>
                                    </div>
                                </form>
                            :
                                (
                                    step === 2 ?
                                        <form action='#' method='POST' className='login_content_form' name='registrationForm'
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if(isConsent) {
                                                    addUser();
                                                }
                                            }}
                                        >
                                            <div className='login_content_form_input_title'>
                                                <span>Numéro de téléphone</span>
                                            </div>

                                            <div className='login_content_form_input_balise'>
                                                <PhoneInput
                                                    placeholder="Enter phone number"
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                    defaultCountry='BJ'
                                                />
                                            </div>
                                            
                                            <div className='login_content_form_input_subText'>
                                                <span>Votre numéro de téléphone</span>
                                            </div>
                                            
                                            <br/>
                            
                                            <div className='login_content_form_button'>
                                                <button>
                                                    VALIDER
                                                </button>
                                                <a href='/login'>
                                                    Se connecter
                                                </a>
                                            </div>
                            
                                            <div className='login_content_form_button returnHome'>
                                                <a href='/'>
                                                    Retourner à l'accueil
                                                </a>
                                            </div>
                                        </form>
                                    :
                                        (
                                            step === 3 ?
                                                <div className='login_content_stepFinal'>
                                                    <video src={vid2} loop autoPlay={true}></video>
                                                    <div>
                                                        <h3>
                                                            Félicitation
                                                        </h3>
                                                        <p>
                                                            Vous pouvez désormais accéder à tous les services <strong>Ilera</strong> 
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <a href='/login'>
                                                            Continuer
                                                        </a>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        )
                            
                                )
                        )
                }
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationView);