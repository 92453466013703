import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import '../styles/dashboard.style.scss';

import DashboardPatientAGenda from './dashboard_patient.agenda.screen';
import DashboardPatientAppointment from './dashboard_patient.appointment.screen';
import DashboardPatientPrescription from './dashboard_patient.prescription.screen';
import DashboardPatientPayments from './dashboard_patient.payments.screen';
import DashboardDoctorMessages from './dashboard_doctor.messages.screen';
import DashboardPatientRequest from './dashboard_patient.request.screen';
import DashboardPatientProfile from './dashboard_patient.profile.screen';

// import userIMG from '../assets/images/people/Example18.jpg';

function _generateMenu(listElementsOfMenu, parent=null) {
    const currentPath = window.location.pathname;
    
    return (
        <div>
            {
                listElementsOfMenu.map((menu, index) => (
                    <div key={menu.name + "_" + index}>
                        <a
                            className={"dashboard-menu-content-item " + (currentPath.split(menu.link).length>1&&menu.name!=="Agenda"?" activeDashboard" : "inactiveDashboard")}
                            key={index + "_" + menu.name}
                            href={menu.link ? menu.link : "/#"}
                            onClick={() => {
                                if( !menu.subMenus ) {
                                    if(menu.action) {
                                        menu.action( index );
                                    }
                                }
                            }}
                            style={{
                                borderBottom: "1px solid #ffffff11"
                            }}
                        >
                            <div className='dashboard-menu-content-item_'>
                                <span className='dashboard-menu-content-item-icon'
                                    style={{
                                        color: "#ffffff",
                                    }}
                                >
                                    { menu.icon }
                                </span>
                                <span className='dashboard-menu-content-item-text'>
                                    {menu.name}
                                </span>
                            </div>

                            {
                                menu.subMenus ?
                                    <div>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right menuChevron" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            // style={{
                                            //     transform: this.state.currentSubMenuOpen.includes(menu.name) ? "rotate(90deg)" : "rotate(0deg)",
                                            // }}
                                        >
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </div>
                                :
                                    null
                            }
                        </a>
                        {
                            menu.subMenus ?
                                <div className='subMenu_container'
                                    // style={{
                                    //     opacity: this.state.currentSubMenuOpen.includes(menu.name) ? 1 : 0,
                                    //     maxHeight: this.state.currentSubMenuOpen.includes(menu.name) ? "50em" : "0em",
                                    // }}
                                >
                                    <div className='SubMenu'>
                                        {
                                            _generateMenu(menu.subMenus, menu.name)
                                        }
                                    </div>
                                </div>
                            :
                                null
                        }
                    </div>
                ))
            }
        </div>
    )
}


function DashboardPatient( props ) {
    const [user, setUser] = useState(null);
    const [viewMenu, setViewMenu] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        const lastUser = JSON.parse(localStorage.getItem("ileraLastLogin"));
        setUser(currentUser);
        if(lastUser === null ) {
            setIsFirstLogin(true);
            localStorage.setItem("ileraLastLogin", JSON.stringify(currentUser));
        } else {
            if(lastUser.username !== currentUser.username) {
                setIsFirstLogin(true);
                localStorage.setItem("ileraLastLogin", JSON.stringify(currentUser));
            }
        }
        // console.log(currentUser);
    },[]);
    const menuList = [
        {
            icon:  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24">
                        <g data-name="agenda book" id="agenda_book">
                            <path d="M22,5.5A1.5,1.5,0,0,0,20.5,4H19V3.5A1.5,1.5,0,0,0,17.5,2H3.5A1.5,1.5,0,0,0,2,3.5v17A1.5,1.5,0,0,0,3.5,22h14A1.5,1.5,0,0,0,19,20.5V20h1.5A1.5,1.5,0,0,0,22,18.5v-3a1.49,1.49,0,0,0-.39-1,1.49,1.49,0,0,0,.39-1v-3a1.49,1.49,0,0,0-.39-1,1.49,1.49,0,0,0,.39-1ZM20.5,5a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5H19V5Zm.5,5.5v3a.5.5,0,0,1-.5.5H19V10h1.5A.5.5,0,0,1,21,10.5ZM15,3V7.29L13.85,6.15a.48.48,0,0,0-.7,0L12,7.29V3ZM18,20.5a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5V3.5A.5.5,0,0,1,3.5,3H11V8.5a.5.5,0,0,0,.31.46.48.48,0,0,0,.54-.11L13.5,7.21l1.65,1.64A.47.47,0,0,0,15.5,9a.43.43,0,0,0,.19,0A.5.5,0,0,0,16,8.5V3h1.5a.5.5,0,0,1,.5.5v17Zm3-2a.5.5,0,0,1-.5.5H19V15h1.5a.5.5,0,0,1,.5.5Z"/>
                            <path d="M15.5,11H5.5a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,15.5,11ZM15,15H6V12h9Z"/>
                            <path d="M15.5,18H5.5a.5.5,0,0,0,0,1h10a.5.5,0,0,0,0-1Z"/>
                        </g>
                    </svg>,
            name: "Mon agenda",
            link: "/dashboard/patient"
        },{
            icon:  <svg width="1em" height="1em" viewBox="0 0 16 16">
                        <path d="m 6.5 0 c -3.578125 0 -6.5 2.921875 -6.5 6.5 s 2.921875 6.5 6.5 6.5 c 0.167969 0 0.335938 -0.007812 0.5 -0.019531 v -2.007813 c -0.164062 0.019532 -0.332031 0.027344 -0.5 0.027344 c -2.496094 0 -4.5 -2.003906 -4.5 -4.5 s 2.003906 -4.5 4.5 -4.5 s 4.5 2.003906 4.5 4.5 c 0 0.167969 -0.007812 0.335938 -0.027344 0.5 h 2.007813 c 0.011719 -0.164062 0.019531 -0.332031 0.019531 -0.5 c 0 -3.578125 -2.921875 -6.5 -6.5 -6.5 z m 0 3 c -0.277344 0 -0.5 0.222656 -0.5 0.5 v 2.5 h -1.5 c -0.277344 0 -0.5 0.222656 -0.5 0.5 s 0.222656 0.5 0.5 0.5 h 2 c 0.277344 0 0.5 -0.222656 0.5 -0.5 v -3 c 0 -0.277344 -0.222656 -0.5 -0.5 -0.5 z m 4.5 5 v 3 h -3 v 2 h 3 v 3 h 2 v -3 h 3 v -2 h -3 v -3 z m 0 0" fill="currentColor"/>
                    </svg>,
            name: "Rendez-vous",
            link: "/dashboard/patient/appointment"
        },
        {
            icon:  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M7 9H17M7 13H17M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>,
            name: "Discussions",
            link: "/dashboard/patient/messages"
        },
        {
            icon:  <svg fill="currentColor" width="1em" height="1em" viewBox="-3 0 24 24"><path d="m15.76 2.852h-2.577v1.221h2.577.007c.655 0 1.186.531 1.186 1.186v.008 16.326.007c0 .656-.532 1.187-1.187 1.187-.002 0-.005 0-.007 0h-13.351c-.002 0-.005 0-.007 0-.656 0-1.187-.532-1.187-1.187 0-.003 0-.005 0-.008v-16.326c0-.002 0-.005 0-.007 0-.656.532-1.187 1.187-1.187h.007 2.577v-1.22h-2.577c-1.331 0-2.411 1.08-2.411 2.411v16.326c.001 1.331 1.08 2.41 2.411 2.41h13.351c1.331 0 2.411-1.08 2.411-2.411v-16.326c0-1.331-1.08-2.411-2.411-2.411z"/><path d="m12.605 2.225h-1.319c0-1.229-.996-2.225-2.225-2.225s-2.225.996-2.225 2.225h-1.31v3.057h7.073v-3.057zm-2.258 0h-2.57c0-.01 0-.021 0-.032 0-.71.576-1.286 1.286-1.286s1.286.576 1.286 1.286v.034-.002z"/><path d="m5.947 8.512h9.458v1.17h-9.458z"/><path d="m5.947 13.41h9.458v1.17h-9.458z"/><path d="m5.947 18.25h9.458v1.176h-9.458z"/><path d="m2.628 7.757h2.68v2.68h-2.68z"/><path d="m2.628 12.655h2.68v2.68h-2.68z"/><path d="m2.628 17.497h2.68v2.68h-2.68z"/></svg>,
            name: "Ordonnances médicale",
            link: "/dashboard/patient/prescription"
        },
        {
            icon:  <svg fill="currentColor" height="1em" width="1em" version="1.1" id="Layer_1" 
            viewBox="0 0 512 512">
                    <g transform="translate(1 1)">
                        <g>
                            <g>
                                <path d="M297.667,186.733c-20.212,0-38.877,6.307-54.153,17.067h-99.447c-5.12,0-8.533,3.413-8.533,8.533
                                    c0,5.12,3.413,8.533,8.533,8.533h81.049c-8.124,9.872-14.261,21.449-17.817,34.133h-63.232c-5.12,0-8.533,3.413-8.533,8.533
                                    s3.413,8.533,8.533,8.533h60.119c-0.25,2.81-0.385,5.655-0.385,8.533c0,8.884,1.22,17.469,3.499,25.6h-63.232
                                    c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h69.884c15.471,30.455,47.053,51.2,83.716,51.2
                                    c52.053,0,93.867-41.813,93.867-93.867C391.533,228.547,349.72,186.733,297.667,186.733z M297.667,357.4
                                    c-42.667,0-76.8-34.133-76.8-76.8c0-4.887,0.468-9.654,1.325-14.279c0.155-0.536,0.262-1.103,0.322-1.697
                                    c3.798-18.206,13.973-33.957,28.113-44.871c0.677-0.379,1.306-0.846,1.866-1.397c12.648-9.16,28.233-14.556,45.174-14.556
                                    c42.667,0,76.8,34.133,76.8,76.8C374.467,323.267,340.333,357.4,297.667,357.4z"/>
                                <path d="M344.6,238.787c-4.267-2.56-9.387-1.707-11.947,2.56l-45.227,68.267l-26.453-26.453c-3.413-3.413-8.533-3.413-11.947,0
                                    c-3.413,3.413-3.413,8.533,0,11.947l34.133,34.133c1.707,1.707,3.413,2.56,5.973,2.56c3.413,0,5.973-1.707,6.827-4.267l51.2-76.8
                                    C349.72,246.467,348.867,241.347,344.6,238.787z"/>
                                <path d="M459.8-1H127c-23.893,0-42.667,18.773-42.667,42.667v349.867H24.6c-14.507,0-25.6,11.093-25.6,25.6V459.8
                                    c0,28.16,23.04,51.2,51.2,51.2h319.147h5.12c12.8,0,24.747-5.12,34.133-13.653c11.093-9.387,17.067-23.04,17.067-37.547V118.467
                                    H485.4c14.507,0,25.6-11.093,25.6-25.6V50.2C511,22.04,487.96-1,459.8-1z M50.2,493.933c-18.773,0-34.133-15.36-34.133-34.133
                                    v-42.667c0-4.267,3.413-8.533,8.533-8.533h68.267h230.4v49.493v1.707c0,12.8,4.267,24.747,12.8,34.133H50.2z M408.6,50.2v409.6
                                    c0,10.24-4.267,18.773-11.093,25.6c-6.422,4.995-14.038,8.189-21.849,8.606c-0.387-0.043-0.781-0.072-1.191-0.072
                                    c-5.787,0-11.247-1.464-16.035-4.032c-10.703-6.039-18.098-17.995-18.098-31.808v-58.027c0-5.12-3.413-8.533-8.533-8.533H101.4
                                    V41.667c0-14.507,11.093-25.6,25.6-25.6h294.4c-1.643,2.008-3.159,4.139-4.542,6.364c-4.797,7.377-7.756,16.044-8.196,25.346
                                    C408.621,48.581,408.6,49.389,408.6,50.2z M493.933,92.867c0,4.267-3.413,8.533-8.533,8.533h-59.733V50.2
                                    c0-18.773,15.36-34.133,34.133-34.133c18.773,0,34.133,15.36,34.133,34.133V92.867z"/>
                                <path d="M203.8,67.267h102.4c5.12,0,8.533-3.413,8.533-8.533S311.32,50.2,306.2,50.2H203.8c-5.12,0-8.533,3.413-8.533,8.533
                                    S198.68,67.267,203.8,67.267z"/>
                                <path d="M144.067,118.467h221.867c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H144.067
                                    c-5.12,0-8.533,3.413-8.533,8.533S138.947,118.467,144.067,118.467z"/>
                                <path d="M144.067,169.667h221.867c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H144.067
                                    c-5.12,0-8.533,3.413-8.533,8.533S138.947,169.667,144.067,169.667z"/>
                            </g>
                        </g>
                    </g>
                    </svg>,
            name: "Mes Factures",
            link: "/dashboard/patient/payments"
        },{
            icon:  <svg fill="currentColor" width="1em" height="1em" viewBox="-3 0 24 24"><path d="m15.76 2.852h-2.577v1.221h2.577.007c.655 0 1.186.531 1.186 1.186v.008 16.326.007c0 .656-.532 1.187-1.187 1.187-.002 0-.005 0-.007 0h-13.351c-.002 0-.005 0-.007 0-.656 0-1.187-.532-1.187-1.187 0-.003 0-.005 0-.008v-16.326c0-.002 0-.005 0-.007 0-.656.532-1.187 1.187-1.187h.007 2.577v-1.22h-2.577c-1.331 0-2.411 1.08-2.411 2.411v16.326c.001 1.331 1.08 2.41 2.411 2.41h13.351c1.331 0 2.411-1.08 2.411-2.411v-16.326c0-1.331-1.08-2.411-2.411-2.411z"/><path d="m12.605 2.225h-1.319c0-1.229-.996-2.225-2.225-2.225s-2.225.996-2.225 2.225h-1.31v3.057h7.073v-3.057zm-2.258 0h-2.57c0-.01 0-.021 0-.032 0-.71.576-1.286 1.286-1.286s1.286.576 1.286 1.286v.034-.002z"/><path d="m5.947 8.512h9.458v1.17h-9.458z"/><path d="m5.947 13.41h9.458v1.17h-9.458z"/><path d="m5.947 18.25h9.458v1.176h-9.458z"/><path d="m2.628 7.757h2.68v2.68h-2.68z"/><path d="m2.628 12.655h2.68v2.68h-2.68z"/><path d="m2.628 17.497h2.68v2.68h-2.68z"/></svg>,
            name: "Rechercher un médecin",
            link: "/dashboard/patient/request"
        },{
            icon:  <svg fill="currentColor" width="1em" height="1em" viewBox="-3 0 24 24"><path d="m15.76 2.852h-2.577v1.221h2.577.007c.655 0 1.186.531 1.186 1.186v.008 16.326.007c0 .656-.532 1.187-1.187 1.187-.002 0-.005 0-.007 0h-13.351c-.002 0-.005 0-.007 0-.656 0-1.187-.532-1.187-1.187 0-.003 0-.005 0-.008v-16.326c0-.002 0-.005 0-.007 0-.656.532-1.187 1.187-1.187h.007 2.577v-1.22h-2.577c-1.331 0-2.411 1.08-2.411 2.411v16.326c.001 1.331 1.08 2.41 2.411 2.41h13.351c1.331 0 2.411-1.08 2.411-2.411v-16.326c0-1.331-1.08-2.411-2.411-2.411z"/><path d="m12.605 2.225h-1.319c0-1.229-.996-2.225-2.225-2.225s-2.225.996-2.225 2.225h-1.31v3.057h7.073v-3.057zm-2.258 0h-2.57c0-.01 0-.021 0-.032 0-.71.576-1.286 1.286-1.286s1.286.576 1.286 1.286v.034-.002z"/><path d="m5.947 8.512h9.458v1.17h-9.458z"/><path d="m5.947 13.41h9.458v1.17h-9.458z"/><path d="m5.947 18.25h9.458v1.176h-9.458z"/><path d="m2.628 7.757h2.68v2.68h-2.68z"/><path d="m2.628 12.655h2.68v2.68h-2.68z"/><path d="m2.628 17.497h2.68v2.68h-2.68z"/></svg>,
            name: "Notifications",
            link: "/dashboard/patient/notification"
        },
        
        // {
        //     icon:   <svg width="1em" height="1em" viewBox="0 -0.5 25 25" fill="none">
        //                 <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="currentColor"/>
        //             </svg>,
        //     name: "Déconnexion",
        //     link: "#",
        //     action: (index)=>{
        //         localStorage.removeItem("ilera");
        //         window.location.href = "/";
        //     }
        // },
    ]

    return (
        <div>
            <main className='dashboard'>
                    <aside className={'dashboard-menu ' + (viewMenu? "menuACtive" : "menuInactive")} id="aside-menu">
                        <div className='dashboard-menu-content bg-color-blue no-view-scroll'>
                            <div className='dashboard-menu-content-head'>
                                <div className='dashboard-menu-content-head-img'>
                                    {/* <img src={userIMG} alt='ilera'/> */}
                                    <svg fill="currentColor" width="2.5em" height="2.5em" viewBox="0 0 32 32" style={{
                                                fillRule:"evenodd",
                                                clipRule:"evenodd",
                                                strokeLinejoin:"round",
                                                strokeMiterlimit:2
                                            }} version="1.1">
                                        <path d="M9.731,14.075c-1.387,0.252 -2.676,0.921 -3.687,1.932c-1.309,1.309 -2.044,3.084 -2.044,4.935l0,4.039c0,1.657 1.343,3 3,3c4.184,-0 13.816,-0 18,-0c1.657,-0 3,-1.343 3,-3l0,-4.039c0,-1.851 -0.735,-3.626 -2.044,-4.935c-1.011,-1.011 -2.3,-1.68 -3.687,-1.932c0.468,-0.939 0.731,-1.997 0.731,-3.117c0,-3.863 -3.137,-7 -7,-7c-3.863,0 -7,3.137 -7,7c0,1.12 0.263,2.178 0.731,3.117Zm11.169,1.88c-1.262,1.239 -2.993,2.003 -4.9,2.003c-1.907,0 -3.638,-0.764 -4.9,-2.003c-0.04,0.005 -0.08,0.007 -0.12,0.007c-1.321,0 -2.588,0.525 -3.521,1.459c-0.934,0.934 -1.459,2.201 -1.459,3.521c0,0 0,4.039 0,4.039c0,0.552 0.448,1 1,1l18,-0c0.552,-0 1,-0.448 1,-1c-0,-0 0,-4.039 0,-4.039c0,-1.32 -0.525,-2.587 -1.459,-3.521c-0.933,-0.934 -2.2,-1.459 -3.521,-1.459c-0.04,0 -0.08,-0.002 -0.12,-0.007Zm-4.9,-9.997c2.76,0 5,2.241 5,5c0,2.76 -2.24,5 -5,5c-2.76,0 -5,-2.24 -5,-5c0,-2.759 2.24,-5 5,-5Z"/><path d="M20,20.008l-1,-0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1l1,-0l0,1c-0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1l0,-1l1,-0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-1,-0l0,-1c-0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1l0,1Z"/>
                                    </svg>
                                </div>
                                <div className='dashboard-menu-content-head-text'>
                                    {/* <span>
                                        Bienvenu
                                    </span> */}
                                    <h2>
                                        {user!=null ? (user.name ? user.name : user.username) : ""}
                                    </h2>
                                </div>
                                <div className='dashboard-menu-content-head-close'>
                                    <button
                                        onClick={(e)=>{
                                            setViewMenu(false);
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Menu / Close_SM">
                                                <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='dashboard-menu-content-body'>
                                {
                                    _generateMenu(menuList)
                                }
                            </div>
                        </div>
                    </aside>
                    <div className="dashboard-contain">
                        <div className='dashboard-contain-head'>
                            <div className='dashboard-contain-head-user'>
                                <div className='dashboard-contain-head-menu'>
                                    <button
                                        onClick={(e) => {
                                            setViewMenu(true);
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                            <path d="M4 6H20M4 12H14M4 18H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>

                                    <div className='dashboard-contain-head-menu-role'>
                                        <div>
                                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 32 32" style={{
                                                fillRule:"evenodd",
                                                clipRule:"evenodd",
                                                strokeLinejoin:"round",
                                                strokeMiterlimit:2
                                            }} version="1.1">
                                        <path d="M9.731,14.075c-1.387,0.252 -2.676,0.921 -3.687,1.932c-1.309,1.309 -2.044,3.084 -2.044,4.935l0,4.039c0,1.657 1.343,3 3,3c4.184,-0 13.816,-0 18,-0c1.657,-0 3,-1.343 3,-3l0,-4.039c0,-1.851 -0.735,-3.626 -2.044,-4.935c-1.011,-1.011 -2.3,-1.68 -3.687,-1.932c0.468,-0.939 0.731,-1.997 0.731,-3.117c0,-3.863 -3.137,-7 -7,-7c-3.863,0 -7,3.137 -7,7c0,1.12 0.263,2.178 0.731,3.117Zm11.169,1.88c-1.262,1.239 -2.993,2.003 -4.9,2.003c-1.907,0 -3.638,-0.764 -4.9,-2.003c-0.04,0.005 -0.08,0.007 -0.12,0.007c-1.321,0 -2.588,0.525 -3.521,1.459c-0.934,0.934 -1.459,2.201 -1.459,3.521c0,0 0,4.039 0,4.039c0,0.552 0.448,1 1,1l18,-0c0.552,-0 1,-0.448 1,-1c-0,-0 0,-4.039 0,-4.039c0,-1.32 -0.525,-2.587 -1.459,-3.521c-0.933,-0.934 -2.2,-1.459 -3.521,-1.459c-0.04,0 -0.08,-0.002 -0.12,-0.007Zm-4.9,-9.997c2.76,0 5,2.241 5,5c0,2.76 -2.24,5 -5,5c-2.76,0 -5,-2.24 -5,-5c0,-2.759 2.24,-5 5,-5Z"/><path d="M20,20.008l-1,-0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1l1,-0l0,1c-0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1l0,-1l1,-0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-1,-0l0,-1c-0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1l0,1Z"/></svg>
                                            <span>
                                                PATIENT
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='dashboard-contain-head-user-profile'>
                                    <a href='/dashboard/patient/profile'>
                                        <svg width="0.8em" height="0.8em" viewBox="0 0 20 20" version="1.1">
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -2159.000000)" fill="currentColor">
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>
                                            Profil
                                        </span>
                                    </a>

                                    <a href='/'>
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                            <path d="M15 16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10L8 6.5M12 3L20 10L20 20H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span>
                                            Accueil
                                        </span>
                                    </a>

                                    <a href='/#' onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.removeItem("ilera");
                                        window.location.href = "/";
                                    }}>
                                        <svg width="1em" height="1em" viewBox="0 -0.5 25 25" fill="none">
                                            <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="currentColor"/>
                                        </svg>
                                        <span>
                                            Déconnexion
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Routes>
                            <Route path='/appointment' element={<DashboardPatientAppointment/>}/>
                            <Route path='/prescription' element={<DashboardPatientPrescription/>}/>
                            <Route path='/payments' element={<DashboardPatientPayments/>}/>
                            <Route path='/messages' element={<DashboardDoctorMessages/>}/>
                            <Route path='/request' element={<DashboardPatientRequest/>}/>
                            <Route path='/profile' element={<DashboardPatientProfile/>}/>
                            <Route path='*' element={<DashboardPatientAGenda/>}/>
                        </Routes>
                    </div>
                    {
                        isFirstLogin &&
                            <div className='dashboardTutorial'>
                                <div className='dashboard-contain-head'>
                                    <div className='dashboard-contain-head-user'>
                                        <div className='dashboard-contain-head-menu'>
                                            

                                            <div className='dashboard-contain-head-menu-role'>
                                                
                                            </div>
                                        </div>
                                        <div className='dashboard-contain-head-user-profile'>
                                            <a href='/dashboard/patient/profile'>
                                                <svg width="0.8em" height="0.8em" viewBox="0 0 20 20" version="1.1">
                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -2159.000000)" fill="currentColor">
                                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                                <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span style={{color: "#ffffff", fontWeight: "bold"}}>
                                                    Profil
                                                </span>
                                                <a href='##' className='profileDescription'
                                                     onClick={(e)=>{
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <span>
                                                        Rendez-vous dans votre profil pour modifier ou mettre à jour vos informations personnelles
                                                    </span>
                                                    <div>
                                                        <a href='##'
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setIsFirstLogin(false);
                                                            }}
                                                        >
                                                            Terminer
                                                        </a>
                                                    </div>
                                                </a>
                                            </a>

                                            <a href='##' style={{opacity: 0, position: "relative", zIndex: -100, }}>
                                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15 16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10L8 6.5M12 3L20 10L20 20H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <span>
                                                    Accueil
                                                </span>
                                            </a>

                                            <a href='/#' style={{opacity: 0, position: "relative", zIndex: -100, }}>
                                                <svg width="1em" height="1em" viewBox="0 -0.5 25 25" fill="none">
                                                    <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="currentColor"/>
                                                </svg>
                                                <span>
                                                    Déconnexion
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </main>

            
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPatient);