import React, {useState} from 'react';
import { connect } from 'react-redux';

import {
    useParams
} from "react-router-dom";



import '../styles/login.screen.style.scss';

/**Imortation des helpers */
import AuthRequest from '../helpers/auth.requests.helper';
import Verifier from '../helpers/verifier.helper';

/**Importation des images */
// import ilera from '../assets/images/ilèra_logo.png'

import loader from '../assets/gif/loader.gif';

const Auth = new AuthRequest();
const VERF = new Verifier();

function ChangePasswordView( props ) {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password_verification_results, setpassword_verification_results] = useState(
        {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
            like_personal_information: true,
            isOk: false,
            isConfirmed: false,
        }
    );

    const { url } = useParams();

    function SendResetCode() {
        setIsLoading(true);
        let data = {
            "password": document.forms["changePassword"]["password"].value,
            "repassword": document.forms["changePassword"]["confirmPassword"].value
        };

        console.log(data);
        Auth.changePassword(data, url).then((response) => {
            setIsLoading(false);
            // let user = response.data;
            // console.log(response.data);
            if(response.data.success === true) {
                alert(response.data.message);
                window.location.href = "/login";
            }
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    return (
        <div className='login'>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Changer le mot de passe
                        </div>
                        <div className='login_content_head_title_bottom'>
                            {/* <span>Cliquez pour reçevoir le code de réinitialisation par email</span>  */}
                            {/* <img src={ilera} alt='ilera'/> */}
                        </div>
                    </div>
                </div>

                <form name="changePassword" action='#' method='POST' className='login_content_form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        SendResetCode();
                    }}
                >

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Nouveau mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='password' required placeholder='***********' type='password'
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let password_verification_results_ = {
                                        number: false,
                                        string: false,
                                        special_character: false,
                                        minimum_character: false,
                                        like_personal_information: true,
                                        isOk: false,
                                        isConfirmed: false,
                                    };
                                    let result = VERF._password(value, 8);

                                    password_verification_results_.minimum_character = result.minimum_character;
                                    password_verification_results_.special_character = result.special_character;
                                    password_verification_results_.string = result.string;
                                    password_verification_results_.number = result.number;

                                    
                                    if( 
                                        
                                        ( email.length > 0 && value.toLowerCase().split(email.toLowerCase()).length > 1 )
                                        // ( document.forms["changePassword"]["mail"].value.length > 0 && value.toLowerCase().split(document.forms["changePassword"]["mail"].value.toLowerCase()).length > 1 )
                                    ) {
                                        password_verification_results_.like_personal_information = true;
                                    }else {
                                        password_verification_results_.like_personal_information = false;
                                    }

                                    if( document.forms["changePassword"]["confirmPassword"].value.length > 0 &&  document.forms["changePassword"]["confirmPassword"].value === value ) {
                                        password_verification_results_.isConfirmed = true;
                                    }else {
                                        password_verification_results_.isConfirmed = false;
                                    }

                                    setpassword_verification_results(password_verification_results_);
                                }}
                            />
                        </div>
                        <div className='login_content_form_input_verf'>
                            <span
                                style={{
                                    color: password_verification_results.like_personal_information ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.minimum_character ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au minimum 8 caractères.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.number ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins un chiffre.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.string ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins une lettre.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.special_character ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « # », « % », « & », « $ » et « _ ».</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Confirmez le nouveau mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='confirmPassword' required placeholder='***********' type='password'
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if( document.forms["changePassword"]["password"].value.length > 0 &&  document.forms["changePassword"]["password"].value === value ) {
                                        setpassword_verification_results({
                                            ...password_verification_results,
                                            isConfirmed: true,
                                        })
                                    }else {
                                        setpassword_verification_results({
                                            ...password_verification_results,
                                            isConfirmed: false,
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div className='login_content_form_input_verf'>
                            <span
                                style={{
                                    color: !password_verification_results.isConfirmed ? "#d11919" : "#5dc269",
                                }}
                            >confirmer</span>
                        </div>
                    </div>

                    <div className='login_content_form_button'>
                        <button>
                            Valider
                        </button>
                    </div>

                    <div className='login_content_form_button returnHome'>
                        <a href='/' style={{fontSize: "1em"}}>
                            Retourner à l'accueil
                        </a>
                    </div>
                </form>
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);