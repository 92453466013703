import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle';

/**Importation des images */


// import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.default.min.css';
import './assets/css/hover-min.css';

import './assets/js/scroll';

import '../styles/policy.screen.style.scss';

import Xanimation from '../helpers/x-animations';
const animer = new Xanimation();
animer.init( 300 );

function IleraConsent( props ) {
    useEffect(() => {
        window.onload = animer.start();
        return () => {
            animer.stop();
        }
    },[]);
    return (
        <>
            <section className="policy_section1">
                <div className='policy_section1_title'>
                    <p>
                        .Notice d’information et de consentement
                    </p>
                </div>
                <div className='policy_section1_carder'>
                    <div className='policy_section1_carder_border'>
                        <div className='policy_section1_carder_plein'></div>
                    </div>
                </div>
            </section>

            <div className='policy_content'>
            <h2 class="uppercase mb-5 mb-md-3-3 text-left">À la collecte de données de santé à caractère personnel et à une prise en charge télémédicale, à l’utilisation
            des données pour l’évaluation des pratiques, à l’hébergement des données à caractère personnel.</h2> 
            
            <div class="mb-6 mb-md-3-3"><p class="pb-1-3">Dans le cadre de votre prise en charge via la plateforme Hellocare vous pouvez bénéficier de consultations à distance avec des professionnels de
          santé.</p> <p class="pb-1-3">Pour pouvoir bénéficier de cette technologie, vous devez accepter la présente note d’information, manifestant votre consentement libre et éclairé. Ce
          dernier est recueilli de manière dématérialisée, lors du lancement de chaque consultation à distance, lorsque vous validez les conditions générales de vente.</p> <p class="pb-1-3">Vous pourrez retirer votre consentement à tout moment, sur simple demande adressée à la SAS Hellocare.</p> <h3 class="font-size-1-2 uppercase bold pb-1-3">Vous consentez expressément&nbsp;:</h3> <ul class="my-0 pb-1-3 pl-0"><li><p>• à être pris(e) en charge par télémédecine&nbsp;;</p></li></ul> <p class="pb-1-3">Ce service de télémédecine vise à améliorer votre prise en charge et suivi médical à distance, participant ainsi aux soins de premier recours et à la
          prévention en santé. Porté par la SAS Hellocare, il a pour objet de répondre aux enjeux suivants&nbsp;:</p> <ul class="my-0 pb-1-3 pl-0"><li><p>• Amener une réponse médicale rapide en limitant les déplacements des patients&nbsp;;</p></li> <li><p>• Faciliter l’accès aux conseils d’un professionnel de santé&nbsp;;</p></li> <li><p>• Désengorger les structures médicales d’urgences&nbsp;;</p></li> <li><p>• Améliorer le suivi des patients par la mise à disposition d’un dossier médical en ligne.</p></li></ul> <p class="pb-1-3">Les professionnels de santé ont accès, de manière rapide, facile et confidentielle, à toutes les informations rassemblées dans votre dossier médical.</p> <h3 class="font-size-1-2 uppercase bold pb-1-3">Vous êtes informé(e) que si vous refusez de consentir à la télé-médecine, la consultation en vidéo ou en audio ne pourra pas
          être effectuée.</h3> <p class="pb-1-3">Le service Hellocare se fait avec l’aide de dispositifs de télémédecine, avec pour finalité d’améliorer votre suivi et votre prise en charge, en
          partageant les données de santé à caractère personnel relatives à votre santé. Celles-ci sont consultées uniquement par des personnes soumises au secret
          professionnel.</p> <p class="pb-1-3">Les conditions de la télémédecine vous sont expliquées dans la présente note et vous pourrez poser les questions que vous souhaitez aux médecins.</p> <p class="pb-1-3">L’utilisation de dispositifs de télémédecine et le partage des données de santé entre les différents professionnels de santé supposent la collecte et le
          traitement de données à caractère personnel par Hellocare.</p> <p class="pb-1-3">Les données à caractère personnel ainsi traitées sont réservées à l’équipe médicale en charge de votre prise en charge, à vos médecins et les
          professionnels de santé en charge de vos soins, ainsi qu’aux équipes de Hellocare en charge de la maintenance du dispositif.</p> <p class="pb-1-3">Ces données ne feront l’objet d’aucune exploitation commerciale et ne seront ni cédées, ni utilisées à d’autres fins.</p> <p class="pb-1-3">En sa qualité de responsable du traitement au sens de la loi « Informatique et Liberté » n°78-17 du 6 janvier 1978, modifié par les lois n°94-548 du 1er
          juillet 1994, n°2002-303 du 4 mars 2002 et n° 2004-801 du 6 août 2004, la SAS Hellocare a déposé auprès de la Commission Nationale de l’Informatique et des Libertés
          (CNIL) une demande d’autorisation de mise en œuvre du présent traitement de données.</p> <h3 class="font-size-1-2 uppercase bold pb-1-3">Vous êtes informé que si vous refusez le traitement par Hellocare des données à caractère personnel vous concernant, vous ne
          pourrez pas être suivi
          médicalement grâce à la télémédecine et que votre prise en charge ne pourra se faire via ce service de télémédecine.</h3> <p class="pb-1-3">Vous êtes en outre informé que vous disposez d’un droit d’information, d’accès et de rectification des données à caractère personnel vous concernant et
          faisant l’objet d’un traitement dans le cadre du service de télémédecine, ainsi que d’un droit d’opposition au traitement de ces données. L’exercice de ces droits est
          subordonné à l’envoi, en parallèle de la demande, d’un justificatif d’identité à l’adresse suivante&nbsp;:</p> <ul class="my-0 pb-1-3 pl-0"><li><p>• Hellocare SAS – 9 chemin du Mont Gibaou - 13260 Cassis</p></li></ul> <h3 class="font-size-1-2 uppercase bold pb-1-3">Vous consentez également&nbsp;:</h3> <p class="pb-1-3">À l’utilisation des données résultant de votre prise en charge pour l’évaluation ou l’analyse des pratiques ou des activités de soins et de
          prévention.</p> <p class="pb-1-3">Vous êtes informé(e) que les données qualitatives et quantitatives relatives à votre profil, à l’évaluation clinique de votre ou vos pathologies, à
          l’estimation des coûts, à la technique, à votre satisfaction et à l’organisation sont recueillies et analysées dans le cadre de l’étude d’évaluation de ce service de
          télémédecine.</p> <p class="pb-1-3">Au sens de la loi « Informatique et Liberté » n°78-17 du 6 janvier 1978, modifié par les lois n°94-548 du 1er juillet 1994, n°2002-303 du 4 mars 2002 et
          n° 2004-801 du 6 août 2004, et au sens du Règlement général sur la protection des données (UE N° 2016/679), la SAS HELLOCARE est responsable du traitement des données
          collectées dans le cadre de l’utilisation de ce service de télé-consultation. La société a déposé auprès de la Commission Nationale de l’Informatique et des Libertés
          (CNIL) une demande d’autorisation de mise en œuvre du présent traitement de données.</p> <p class="pb-1-3"><span class="bold">Vous consentez également</span> à l’hébergement de vos données à caractère personnel par un hébergeur de données de santé agréé par le
          Ministre de la Santé.</p> <p class="pb-1-3">Les données vous concernant sont enregistrées à l’occasion de votre prise en charge par télémédecine et elles font l’objet d’un traitement informatisé par
          la société Hellocare.</p> <p class="pb-1-3">Vous êtes informé que les données de santé à caractère personnel vous concernant faisant l’objet d’un traitement dans le cadre de la télémédecine sont
          hébergées par Google Cloud Platform. Vous pouvez retirer votre consentement à tout moment pour l’hébergement de ces données.</p> <p class="pb-1-3">Vous avez bien noté que le droit d’accès et de rectification, que vous ouvrent les textes susvisés, pourront s’exercer à tout moment auprès de Hellocare
          et les données vous concernant pourront vous être communiquées directement ou par l’intermédiaire d’un médecin de votre choix.</p></div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(IleraConsent);