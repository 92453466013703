import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle';

/**Importation des images */


// import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.default.min.css';
import './assets/css/hover-min.css';

import './assets/js/scroll';

import '../styles/policy.screen.style.scss';

import Xanimation from '../helpers/x-animations';
const animer = new Xanimation();
animer.init( 300 );

function IleraPolicy( props ) {
    useEffect(() => {
        window.onload = animer.start();
        return () => {
            animer.stop();
        }
    },[]);
    return (
        <>
            <section className="policy_section1">
                <div className='policy_section1_title'>
                    <p>
                        .Données personnelles et confidentialité
                    </p>
                </div>
                <div className='policy_section1_carder'>
                    <div className='policy_section1_carder_border'>
                        <div className='policy_section1_carder_plein'></div>
                    </div>
                </div>
            </section>

            <div className='policy_content'>
                
                <h2 className="uppercase mb-5 mb-md-3-3 text-left">Préambule</h2>
                <div className="mb-6 mb-md-3-3">
                    <p className="pb-1-3">Chez Hellocare , la protection de vos données personnelles, qu’elles soient administratives ou relatives à votre santé, est une priorité et un sujet
                    d’intransigeance.&nbsp;
                    </p>
                    <p className="pb-1-3">Par ailleurs, notre service vous permettant de partager avec vos praticiens des données personnelles de santé, nous avons mis en place des mesures de
                    sécurité élevées.
                    Ces données sont cryptées pour vous garantir une protection des plus élevées.&nbsp;
                    </p>
                    <p className="pb-1-3">Enfin, en&nbsp; utilisant l’application Hellocare, vous constaterez que l’accès à votre compte se fait avec un double niveau d’authentification (deux mots
                    de passe,
                    dont un à usage unique, que vous recevez par sms à chaque fois que vous vous connectez). Cela nous permet de nous assurer que vous êtes bien la personne qui tente
                    d’accéder au compte et de mieux garantir la protection de vos données personnelles.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Hébergement certifié HDS (Hébergeur de Données de Santé)</h3>
                    <p className="pb-1-3">Toutes les données enregistrées dans votre compte sont stockées chez un Hébergeur certifié Données de Santé par l’Agence du Numérique en Santé. Cela
                    garantit que vos
                    données sont hébergées en Europe, dans des conditions garantissant l’intégrité de vos informations à caractère personnel, leur sécurité, confidentialité et
                    disponibilité. Nous avons également sollicité l’autorisation de la CNIL pour le traitement de ces données.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Vos données sont réservées aux soignants</h3>
                    <p className="pb-1-3">L’accès aux informations de santé contenues dans votre compte est régulé. Des profils d’habilitation existent pour limiter au maximum l’accès à vos
                    données à caractère
                    personnel. Ainsi seul le(s) médecin(s) que vous aurez sollicité via le service, les professionnels de santé que vous aurez autorisés et éventuellement le médecin
                    coordonnateur du service pourront consulter vos données personnelles.&nbsp;
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Conformité au RGPD et suivi des recommandations de la CNIL</h3>
                    <p className="pb-1-3">Dans le cadre de la mise en place du RGPD (Règlement Général sur la Protection des Données de l’UE), applicable à compter du 25 mai 2018, Hellocare a
                    effectué les
                    démarches nécessaires pour être en conformité. Nous avons amélioré la lisibilité de notre&nbsp; politique de confidentialité et limité les informations qui font l’objet
                    d’un traitement de données personnelles.&nbsp;
                    </p>
                    <p className="pb-1-3">Vous pouvez également contacter notre Déléguée à la Protection des Données ou nous demander l’accès, la rectification et la suppression de vos données en
                    écrivant à
                    l’adresse suivante : <a href="mailto:confidentialite@hellocare.com" className="red">confidentialite@hellocare.com</a></p>
                    <h2 className="uppercase mb-5 mb-md-3-3 mt-2-6 text-left">Politique de confidentialité de Hellocare</h2>
                    <p className="pb-1-3">Cette politique de confidentialité a pour objet l’information des utilisateurs, patients ou professionnels de santé, de la manière dont nous protégeons
                    les données à
                    caractère personnel via nos sites (hellocare.com / hellocare.pro) et via nos applications mobiles “Hellocare” et “Hellocare pro”.
                    </p>
                    <p className="pb-1-3">Les présentes décrivent la manière dont HELLOCARE et les professionnels de santé abonnés à ses services traitent les données à caractère personnel des
                    utilisateurs
                    (ci-après le/les « Utilisateur(s) ») lors de la navigation sur le site hellocare.com (ci-après le « Site ») et de leur utilisation des services proposés par HELLOCARE
                    sur les sites édités par la société (hellocare.com / hellocare.pro) ou de ses applications mobiles (ci-après la/les ’”Application(s)”).
                    </p>
                    <p className="pb-1-3">Certaines Données Personnelles de l’Utilisateur disposent d’un cadre légal spécifique, relevant des données à caractère personnelles de santé, et sont
                    dénommées dans
                    les présentes «Données Personnelles de Santé».
                    </p>
                    <p className="pb-1-3">La présente politique de confidentialité a été préparée en exécution des dispositions de plusieurs législations, notamment de l’article 13/14 du règlement
                    européen
                    2016/679 (règlement général sur la protection des données). Hellocare se réserve le droit de compléter, modifier ou mettre à jour la présente Politique de
                    Confidentialité, notamment en cas d’évolution technique, réglementaire, légale ou jurisprudentielle. Cependant, les Données Personnelles de l’Utilisateur seront
                    toujours traitées conformément à la politique en vigueur au moment de leur collecte, sauf si une prescription légale impérative venait à en disposer autrement et serait
                    d’application rétroactive.
                    </p>
                    <p className="pb-1-3">La présente politique de confidentialité fait partie intégrante des Conditions Générales d’Utilisation de HELLOCARE.</p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Propriétaire, Responsable de Traitement et sous-traitant</h3>
                    <p className="pb-1-3">Il est rappelé aux Utilisateurs, qu’au sens du RGPD et de la LIL, le Responsable du traitement est la personne physique ou morale qui détermine les moyens
                    et les
                    finalités du traitement. Le Sous-traitant est une personne traitant des données à caractère personnel pour le compte du responsable du traitement. Le sous-traitant agit
                    donc sous l’autorité du responsable du traitement et sur instruction de celui-ci.
                    </p>
                    <p className="pb-1-3">Qu’elle soit responsable de traitement ou sous-traitant, HELLOCARE prend les mesures nécessaires pour assurer la protection et la confidentialité des
                    Données
                    Personnelles et des Données Personnelles de santé qu’elle détient ou qu’elle traite dans le respect des dispositions de la Loi Informatique et Libertés et du RGPD.&nbsp;
                    </p>
                    <p className="pb-1-3">1- Pour les Données Personnelles collectées dans le cadre de : (i) la navigation sur le site ou les applications mobiles, (ii) la création de compte
                    Utilisateur, (iii)
                    la création de statistiques relatives à l’utilisation des services, leur calcul et leur anonymisation : le Responsable de traitement est HELLOCARE, SAS immatriculée au
                    RCS de Marseille sous le numéro 821 232 121, et représentée par sa Présidente WCB Medicale Invest.&nbsp;
                    </p>
                    <p className="pb-1-3">2- Pour les Données Personnelles de Santé collectées (a) lors de la prise de rendez-vous par l’Utilisateur directement via le Site ou l’Application, (b)
                    par le
                    Professionnel de Santé dans son agenda HELLOCARE, (c) lors du partage de documents par l’Utilisateur ou le Professionnel de santé&nbsp; : le Responsable de traitement
                    est chaque Professionnel de Santé individuellement avec lequel l’Utilisateur a pris rendez-vous ou échangé des données . Chaque professionnel de santé est considéré
                    comme responsable de traitement des Données Personnelles de Santé de ses patients. Pour ces données personnelles de santé, HELLOCARE est sous-traitant et agit sur
                    instruction particulière des Professionnels de Santé dans le cadre de la relation avec leurs patients.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Type des données collectées</h3>
                    <p className="pb-1-3">En tant que Sous-traitant, HELLOCARE est susceptible de traiter, en tout ou en partie, les données suivantes :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• Nom</p></li>
                    <li><p>• Prénom</p></li>
                    <li><p>• Date de naissance</p></li>
                    <li><p>• Civilité</p></li>
                    <li><p>• NIR / INS</p></li>
                    <li><p>• Numéro de téléphone</p></li>
                    <li><p>• adresse email</p></li>
                    <li><p>• adresse postale</p></li>
                    <li><p>• Médecin traitant</p></li>
                    <li><p>• Mot de passe</p></li>
                    <li><p>• Motif du rendez-vous</p></li>
                    <li><p>• Spécialité du praticien consulté</p></li>
                    <li><p>• Historique des rendez-vous</p></li>
                    <li><p>• Données appartenant au dossier médical que le Professionnel de Santé souhaite partager avec l’Utilisateur ou partagé par l’Utilisateur avec le Professionnel de
                        Santé</p>
                    </li>
                    <li><p>• Ces mêmes données concernant les proches, rattachés au compte de l’Utilisateur en tant que Ayants-droit</p>
                    </li>
                    </ul>
                    <p className="pb-1-3">En tant que Responsable de Traitement HELLOCARE est susceptible de traiter, en tout ou en partie, les données suivantes :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• Nom</p></li>
                    <li><p>• Prénom</p></li>
                    <li><p>• Données de connexion et d’utilisation du Site ou de l’Application</p></li>
                    <li><p>• Configuration informatique utilisée pour la navigation</p></li>
                    <li><p>• Adresse IP</p></li>
                    <li><p>• Mot de passe</p></li>
                    <li><p>• Adresse email</p></li>
                    <li><p>• Numéro de téléphone</p></li>
                    <li><p>• Consentement aux communications dans le cadre du RGPD</p></li>
                    <li><p>• Autorisation de la caméra pour la téléconsultation</p></li>
                    <li><p>• Autorisation du microphone pour la téléconsultation</p></li>
                    <li><p>• Données de localisation sur acceptation de l’Utilisateur</p></li>
                    </ul>
                    <p className="pb-1-3">Les détails complets sur chaque type de Données personnelles collectées par Hellocare en tant que Responsable de Traitement sont fournis dans les parties
                    consacrées à
                    la présente politique de confidentialité.
                    </p>
                    <p className="pb-1-3">Les Données personnelles peuvent être librement fournies par l’Utilisateur, ou, en cas de Données d’utilisation, collectées automatiquement lorsque de
                    l’utilisation du
                    Site ou des Applications.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Modes et lieux de traitement</h3>
                    <h4 className="font-400 pb-1-3">Collecte et origine des Données</h4>
                    <p className="pb-1-3">Sauf indication contraire, toutes les Données demandées par ce site Web sont obligatoires et leur absence peut rendre impossible la fourniture des
                    Services par ce site
                    Web. Dans le cas où ce site Web précise que certaines Données ne sont pas obligatoires, les Utilisateurs sont libres de ne pas les communiquer sans entraîner de
                    conséquences sur la disponibilité ou le fonctionnement du Service.
                    </p>
                    <p className="pb-1-3">Les Utilisateurs qui auraient des doutes sur les Données personnelles obligatoires sont invités à contacter le Propriétaire.</p>
                    <p className="pb-1-3">Toute utilisation des Cookies – ou d’autres outils de suivi – par ce site Web ou par les propriétaires de services tiers utilisés par ce site Web vise à
                    fournir le
                    Service demandé par l’Utilisateur, outre les autres finalités décrites dans le présent document.
                    </p>
                    <p className="pb-1-3">Les Utilisateurs sont responsables de toute Donnée personnelle de tiers obtenue, publiée ou communiquée par l’intermédiaire de ce site Web et confirment
                    qu’ils
                    obtiennent le consentement du tiers pour fournir les Données au Responsable de Traitement.
                    </p>
                    <h4 className="font-400 pb-1-3">Méthodes de traitement</h4>
                    <p className="pb-1-3">Le Propriétaire prend les mesures de sécurité appropriées afin d’empêcher l’accès, la divulgation, la modification ou la destruction non autorisés des
                    Données.
                    </p>
                    <p className="pb-1-3">Le traitement des Données est effectué à l’aide d’ordinateurs ou d’outils informatiques, en suivant les procédures et les modes organisationnels
                    étroitement liés aux
                    finalités indiquées.&nbsp;
                    </p>
                    <p className="pb-1-3">Outre le Propriétaire, les Données peuvent être accessibles, dans certains cas, à certaines catégories de personnes en charge du fonctionnement du Site ou
                    de
                    l’Application (administration, ventes, marketing, service juridique, administration du système) ou à des parties externes (telles que les fournisseurs tiers de services
                    techniques, les services de messagerie, les fournisseurs d’hébergement, les entreprises informatiques, les agences de communication) désignées, le cas échéant, comme
                    Sous-traitantes par le Propriétaire.&nbsp;
                    </p>
                    <p className="pb-1-3">La liste mise à jour de ces parties peut être demandée à tout moment au Propriétaire.</p>
                    <h4 className="font-400 pb-1-3">Lieux de traitement</h4>
                    <p className="pb-1-3">HELLOCARE utilise différents lieux de traitement, selon le type de données collectées et leur finalité.</p>
                    <p className="pb-1-3">Selon la localisation de l’Utilisateur, les transferts de données peuvent entraîner le transfert des Données de ce dernier vers un pays autre que le sien.
                    Les
                    Utilisateurs ont également le droit de connaître la base juridique des transferts de Données vers un pays situé en dehors de l’Union européenne ou vers toute
                    organisation internationale régie par le droit international public ou créée par deux pays ou plus, comme l’ONU, ainsi que les mesures de sécurité prises par le
                    Propriétaire pour sauvegarder leurs Données.
                    </p>
                    <p className="pb-1-3">Si un tel transfert a lieu, les Utilisateurs peuvent en savoir plus en consultant les sections correspondantes du présent document ou se renseigner auprès
                    du
                    Propriétaire en utilisant les informations fournies dans la section de contact.
                    </p>
                    <p className="pb-1-3">(i) Concernant les données personnelles de santé pour lesquelles HELLOCARE agit en tant que sous-traitant font l’objet d’un traitement spécifique, sont
                    cryptées
                    puis stockées chez une Hébergeur certifié Données de Santé. Les applications mobiles, le logiciel et les espaces sur lesquels peuvent transiter ces données personnelles
                    de santé sont intégralement hébergées par <a href="https://services.google.com/fh/files/misc/hds_cloud_cert_25june2019.pdf?hl=fr" className="red">Google Cloud Services</a>, qui a obtenu la certification des
                    autorités
                    françaises pour traiter et héberger des données de santé. Ces dernières sont cryptées par Hellocare et ne peuvent en aucun cas être consultées en clair par Google.
                    </p>
                    <p className="pb-1-3">(ii) Concernant les données personnelles des utilisateurs pour lesquelles HELLOCARE est Responsable de traitement : (a) les données collectées lors de la
                    création des comptes Utilisateurs et renseignées par l’Utilisateur sont traitées et hébergées dans les mêmes conditions que les données personnelles de santé. (b) Les
                    données collectées à des fins d’analyse statistique ou de marketing sont transférées selon les modalités détaillées décrites dans les présentes. Pour en savoir plus sur
                    le lieu de traitement de ces Données transférées, les Utilisateurs peuvent consulter la section qui contient des détails sur le traitement des Données personnelles.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Base juridique du traitement</h3>
                    <p className="pb-1-3">Le recueil de vos Données Personnelles a pour base légale :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• l’intérêt légitime de HELLOCARE à assurer la meilleure qualité de ses services, à fournir à ses Utilisateurs le meilleur suivi possible de
                        leurs consultations et rendez-vous médicaux, ainsi qu’à améliorer le fonctionnement de son Site et des Applications ;
                        </p></li>
                    <li><p>• l’intérêt légitime de HELLOCARE à recueillir, traiter et produire des données statistiques anonymisées relatives à l’impact de HELLOCARE
                        sur la Santé des Utilisateurs, l’activité des Professionnels de santé et plus généralement du secteur de la santé afin de communiquer auprès du public et d’améliorer
                        ses services ;
                        </p></li>
                    <li><p>• l’intérêt légitime de HELLOCARE à réaliser des sondages facultatifs afin de mieux connaître ses Utilisateurs et améliorer ses services ;
                        </p></li>
                    <li><p>• le consentement des Utilisateurs lorsque celui-ci est requis par la réglementation en vigueur, notamment en matière de relation
                        commerciale et de cookies.
                        </p></li>
                    </ul>
                    <p className="pb-1-3">Dans tous les cas, HELLOCARE vous aidera volontiers à clarifier la base juridique spécifique qui s’applique au traitement, et en particulier si la
                    fourniture de
                    Données personnelles est une exigence légale ou contractuelle, ou une exigence nécessaire pour conclure un contrat.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Temps de conservation des données</h3>
                    <p className="pb-1-3">Les Données personnelles sont traitées et conservées aussi longtemps que requis pour la finalité pour laquelle elles ont été collectées.
                    </p>
                    <p className="pb-1-3">Par conséquent :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• Les Données personnelles collectées à des fins liées à l’exécution d’un contrat entre le Responsable de traitement et l’Utilisateur
                        doivent être conservées jusqu’à la pleine exécution du contrat.
                        </p></li>
                    <li><p>• Les Données personnelles collectées aux fins des intérêts légitimes du Responsable de traitement doivent être conservées aussi longtemps
                        que nécessaire pour atteindre ces objectifs. Les Utilisateurs peuvent trouver des informations spécifiques concernant les intérêts légitimes poursuivis par le le
                        Responsable de traitement dans les sections correspondantes du présent document ou en contactant le Responsable de traitement.
                        </p></li>
                    </ul>
                    <p className="pb-1-3">Le Responsable de traitement peut être autorisé à conserver des Données personnelles plus longtemps chaque fois que l’Utilisateur a donné son consentement
                    à un tel
                    traitement, tant que ce consentement n’est pas retiré. En outre, le Responsable de traitement peut être obligé de conserver des Données personnelles plus longtemps
                    chaque fois que cela est requis pour l’exécution d’une obligation légale ou sur ordre d’une autorité.
                    </p>
                    <p className="pb-1-3">Une fois la période de conservation expirée, les Données personnelles seront supprimées. Par conséquent, le droit d’accès, le droit d’effacement, le droit
                    de
                    rectification et le droit à la portabilité des données ne peuvent être appliqués après l’expiration de la période de conservation.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Finalité du traitement</h3>
                    <p className="pb-1-3">Les données des Utilisateurs de HELLOCARE sont principalement traitées pour&nbsp;:</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• permettre la navigation sur le Site et l’utilisation des Applications&nbsp;;</p></li>
                    <li><p>• permettre à l’Utilisateur de visualiser et gérer son parcours de soin&nbsp;;</p></li>
                    <li><p>• permettre à l’Utilisateur de partager des documents avec les Professionnels de santé&nbsp;;</p></li>
                    <li><p>• effectuer les mises en relation entre les Utilisateurs (patient et professionnel de santé)&nbsp;;</p></li>
                    <li><p>• permettre à l’Utilisateur de gérer les rendez-vous de ses ayants-droits ou proches aidés</p></li>
                    </ul>
                    <p className="pb-1-3">A titre subsidiaire les données des Utilisateurs sont également collectées pour&nbsp;:</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• prévenir et lutter contre la fraude informatique (spamming, hacking…)&nbsp;;</p></li>
                    <li><p>• améliorer la navigation sur le Site et l’utilisation des Applications&nbsp;;</p></li>
                    <li><p>• mener des sondages facultatifs sur les services de HELLOCARE et leurs possibles évolutions&nbsp;;</p></li>
                    <li><p>• mener des sondages facultatifs sur la qualité des Services ou sur la qualité de la prise en charge par les Professionnels de Santé&nbsp;;
                        </p></li>
                    <li><p>• effectuer des statistiques anonymisées sur l’utilisation du Service</p></li>
                    </ul>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Autorisations du dispositif pour accéder aux données personnelles</h3>
                    <p className="pb-1-3">Selon le dispositif particulier de l’Utilisateur, ce site Web pourrait demander certaines autorisations pour lui autoriser l’accès aux Données du
                    dispositif de
                    l’Utilisateur comme décrit ci-dessous.
                    </p>
                    <p className="pb-1-3">Par défaut, ces autorisations doivent être accordées par l’Utilisateur avant que les informations respectives soient accessibles. Une fois que
                    l’autorisation a été
                    donnée, elle peut être révoquée par l’Utilisateur à tout moment. Afin de révoquer ces autorisations, les Utilisateurs peuvent consulter les paramètres du dispositif ou
                    contacter le Propriétaire aux coordonnées fournies dans le présent document.
                    </p>
                    <p className="pb-1-3">La procédure exacte pour contrôler les permissions des applications peut dépendre du dispositif et du logiciel de l’Utilisateur.
                    </p>
                    <p className="pb-1-3">Veuillez noter que la révocation de ces autorisations peut affecter le bon fonctionnement de ce site Web.</p>
                    <p className="pb-1-3">Si l’Utilisateur accorde l’une des autorisations répertoriées ci-dessous, ces Données personnelles respectives peuvent être traitées (c’est-à-dire
                    accessibles,
                    modifiées ou supprimées) par ce site Web.
                    </p>
                    <h4 className="font-400 pb-1-3">Autorisation de la caméra</h4>
                    <p className="pb-1-3">Utilisée pour accéder à la caméra lors des téléconsultations ou enregistrer des images et des vidéos avec le dispositif.</p>
                    <h4 className="font-400 pb-1-3">Autorisation du microphone&nbsp;</h4>
                    <p className="pb-1-3">Utilisée pour accéder au son du microphone du dispositif de l’Utilisateur et lui permettre d’échanger avec le Professionnel de Santé.&nbsp;
                    </p>
                    <p className="pb-1-3">Aucun enregistrement n’est fait par HELLOCARE lors de l’échange audio ou vidéo.</p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Informations détaillées sur le traitement des données personnelles</h3>
                    <p className="pb-1-3">Les Données personnelles sont collectées pour les finalités suivantes à l’aide de plusieurs services :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p className="bold">• Analyses</p>
                        <p className="ml-0-4">Elles permettent à HELLOCARE de surveiller et d’analyser le trafic Web et de suivre l’évolution du comportement de l’Utilisateur. Google Analytics est
                        utilisé, un
                        service d’analyse Web fourni par Google Inc. (« Google »). Google utilise les Données collectées pour suivre et analyser l’utilisation de ce site Web, préparer des
                        rapports sur ses activités et les partager avec d’autres services Google. Google peut utiliser les Données collectées pour contextualiser et personnaliser les
                        publicités de son propre réseau publicitaire.<br/>
                        Données personnelles collectées : Cookies; Données d’utilisation.<br/>
                        Lieu de traitement : États-Unis.</p>
                    </li>
                    <li><p className="bold">• Autorisations du dispositif pour accéder aux données personnelles</p>
                        <p className="ml-0-4">Ce site Web nécessite certaines autorisations des Utilisateurs qui lui permettent d’accéder aux Données du dispositif des Utilisateurs, présentées
                        dans le présent
                        document.<br/>
                        Données personnelles collectées : Autorisation de la caméra ; Autorisation du microphone.</p>
                    </li>
                    <li><p className="bold">• Contact Utilisateur / Formulaire de contact</p>
                        <p className="ml-0-4">En remplissant le formulaire de contact avec leurs Données, l’Utilisateur autorise l’utilisation de ces renseignements par ce site Web aux fins de
                        répondre aux
                        demandes d’information, de citations ou à toute autre demande tel que l’indique l’en-tête du formulaire.<br/>
                        Données personnelles collectées : adresse électronique ; nom de famille ; prénom.</p>
                    </li>
                    <li><p className="bold">• Gestion de la base de données des Utilisateurs</p>
                        <p className="ml-0-4">Ce type de services permet à HELLOCARE de créer des profils utilisateurs à partir d’une adresse électronique, d’un nom de personne ou d’autres
                        informations
                        fournies par l’Utilisateur à ce site Web, et de suivre les activités de celui-ci par le biais de fonctionnalités analytiques. Certains de ces services peuvent
                        également activer l’envoi de messages chronométrés à l’Utilisateur, tels que les courriels basés sur les actions spécifiques effectuées sur ce site Web.</p>
                    </li>
                    <li><p className="bold">• Gestion des balises</p>
                        <p className="ml-0-4">Ce type de service permet au Propriétaire de gérer les balises ou les scripts nécessaires sur ce site Web de manière centralisée. Par conséquent, les
                        Données
                        utilisateurs transitent par le service
                        Google Tag Manager qui est un service de gestion de balises fourni par Google LLC.<br/>
                        Données personnelles collectées : Cookies; Données d’utilisation.<br/>
                        Lieu de traitement : États-Unis</p>
                    </li>
                    <li><p className="bold">• Gestion des contacts et envois de messages</p>
                        <p className="ml-0-4">Ce type de services permet de gérer une base de données d’adresses électroniques, de numéros de téléphone ou de toutes autres coordonnées pour
                        communiquer avec
                        l’Utilisateur. Ces services peuvent aussi collecter des données concernant la date et l’heure auxquelles l’Utilisateur visionne le message, l’heure à laquelle il
                        interagit avec ce message, par exemple en cliquant sur les liens inclus dans celui-ci.<br/>
                        <span className="bold">Mailchimp</span><br/>
                        Mailchimp est un service de gestion d’adresses électroniques et d’envoi de messages fourni par The Rocket Science Group, LLC.<br/>
                        Données personnelles collectées : adresse électronique; date de naissance; Données d’utilisation; nom de famille; pays; prénom; sexe.<br/>
                        Lieu de traitement : États-Unis – <a href="https://mailchimp.com/legal/privacy/" className="red">Politique de confidentialité</a>. Participant au bouclier de protection
                        des
                        données.<br/>
                        <span className="bold">HubSpot</span><br/>
                        Hubspot est un service de gestion de base de données commerciale, d’adresses électroniques et d’envoi de messages<br/>
                        Données personnelles collectées : adresse électronique; date de naissance; Données d’utilisation; nom de famille; pays; prénom; sexe, profession, contrats conclus
                        avec HELLOCARE par les Professionnels de santé.<br/>
                        Lieu de traitement : États-Unis – <a href="https://mailchimp.com/legal/privacy/" className="red">Politique de confidentialité</a>. Participant au bouclier de protection
                        des
                        données.</p>
                    </li>
                    <li><p className="bold">• Remarketing et ciblage comportemental</p>
                        <p className="ml-0-4">Ce type de services permet à ce site Web et à ses partenaires de distribuer, d’optimiser et de présenter des publicités en fonction de l’utilisation
                        antérieure de
                        ce site Web par l’Utilisateur. Cette activité est réalisée en suivant l’évolution des Données d’utilisation et en utilisant les Cookies. Ces informations sont
                        transférées aux partenaires qui gèrent l’activité de remarketing et de ciblage comportemental.<br/>
                        En plus de toute option de retrait proposée par un des services ci-dessous, l’Utilisateur peut décider de refuser l’utilisation de cookies de la part de services
                        tiers en visitant la <a href="http://optout.networkadvertising.org/" className="red">page de retrait de la Network Advertising Initiative</a>.<br/>
                        <span className="bold">Avec Google Ads</span><br/>
                        Le remarketing de Google Ads est un service de remarketing et de ciblage comportemental fourni par Google LLC, qui relie l’activité de ce site Web au réseau
                        publicitaire Google Ads et au Cookie DoubleClick.Les Utilisateurs peuvent renoncer à l’utilisation par Google de cookies pour la personnalisation d‘annonces en
                        consultant les <a href="https://adssettings.google.com/authenticated" className="red">Paramètres des annonces</a> de Google.<br/>
                        Données personnelles collectées : Cookies; Données d’utilisation.<br/>
                        Lieu de traitement : États-Unis<br/>
                        <span className="bold">Avec Facebook Ads</span><br/>
                        Le remarketing de Facebook est un service de remarketing et de ciblage comportemental fourni par Facebook, Inc., qui relie l’activité de ce site Web au réseau
                        publicitaire de Facebook.<br/>
                        Données personnelles collectées : Cookies; Données d’utilisation.<br/>
                        Lieu de traitement : États-Unis</p>
                    </li>
                    <li><p className="bold">• Réseaux sociaux et plate-formes externes</p>
                        <p className="ml-0-4">Ce type de services permet les échanges avec les réseaux sociaux ou d’autres plateformes externes directement à partir des pages de ce site Web.<br/>
                        Les échanges et informations obtenus par ce site Web sont toujours régis par les paramètres de confidentialité définis par l’Utilisateur pour chaque réseau
                        social.<br/>
                        Ce type de service peut quand même collecter les données relatives au trafic des pages sur lesquelles il est installé, même lorsque l’Utilisateur ne l’utilise
                        pas.<br/>
                        Nous vous recommandons de vous déconnecter des services respectifs afin de vous assurer que les données traitées sur ce site Web ne sont pas connectées au profil de
                        l’Utilisateur.<br/>
                        <br/>
                        Le bouton J’aime et les widgets sociaux de Facebook sont des services permettant d’échanger avec le réseau social Facebook fourni par Facebook, Inc.<br/>
                        Données personnelles collectées : Cookies; Données d’utilisation.<br/>
                        Lieu de traitement : États-Unis.</p>
                    </li>
                    </ul>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Droits des Utilisateurs</h3>
                    <p className="pb-1-3">Les Utilisateurs peuvent exercer certains droits concernant leurs Données traitées par le Propriétaire.</p>
                    <p className="pb-1-3">En particulier, les Utilisateurs ont le droit de faire ce qui suit :</p>
                    <ul className="my-0 pb-1-3 pl-0">
                    <li><p>• Retirer leur consentement à tout moment. Les Utilisateurs ont le droit de retirer leur consentement s’ils ont déjà donné leur consentement
                        au traitement de leurs Données personnelles.
                        </p></li>
                    <li><p>• S’opposer au traitement de leurs Données. Les Utilisateurs ont le droit de s’opposer au traitement de leurs Données si le traitement est
                        effectué sur une base juridique autre que le consentement. Des précisions sont ajoutées dans la section correspondante ci-dessous.
                        </p></li>
                    <li><p>• Accéder à leurs Données. Les Utilisateurs ont le droit de savoir si les Données sont traitées par le Propriétaire, d’obtenir des
                        informations sur certains aspects du traitement et d’obtenir une copie des Données en cours de traitement.
                        </p></li>
                    <li><p>• Vérifier et obtenir une rectification. Les Utilisateurs ont le droit de vérifier l’exactitude de leurs Données et de demander qu’elles
                        soient mises à jour ou corrigées.
                        </p></li>
                    <li><p>• Limiter le traitement de leurs Données. Les Utilisateurs ont le droit, sous certaines conditions, de limiter le traitement de leurs
                        Données. Dans ce cas, le Propriétaire traitera leurs Données uniquement pour les stocker.
                        </p></li>
                    <li><p>• Faire supprimer ou effacer leurs Données personnelles. Les Utilisateurs ont le droit, sous certaines conditions, d’obtenir l’effacement de
                        leurs Données auprès du Propriétaire.
                        </p></li>
                    <li><p>• Récupérer leurs Données et les transférer à un autre responsable du traitement. Les Utilisateurs ont le droit de récupérer leurs Données
                        dans un format structuré, couramment utilisé et lisible par machine et, si cela est techniquement possible, de les transmettre à un autre responsable du traitement
                        sans obstacle d’aucune sorte. Cette disposition s’applique, sous réserve que les Données soient traitées par des moyens automatisés et que le traitement repose sur le
                        consentement de l’Utilisateur, sur un contrat auquel l’Utilisateur est partie ou sur des obligations précontractuelles.
                        </p></li>
                    <li><p>• Déposer plainte. Les Utilisateurs ont le droit de déposer une plainte auprès de leur autorité compétente en matière de protection des
                        données.
                        </p></li>
                    </ul>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Informations concernant le droit d’opposition au traitement&nbsp;</h3>
                    <p className="pb-1-3">Lorsque les Données personnelles sont traitées dans l’intérêt public, dans l’exercice d’une autorité officielle dévolue au Propriétaire ou aux fins des
                    intérêts
                    légitimes poursuivis par celui-ci, les Utilisateurs peuvent s’opposer à ce traitement en fournissant un motif lié à leur situation particulière devant justifier cette
                    opposition.
                    </p>
                    <p className="pb-1-3">Les Utilisateurs doivent cependant savoir que si leurs Données personnelles sont traitées à des fins de marketing direct, ils peuvent s’opposer à ce
                    traitement à tout
                    moment sans aucune justification. Pour savoir si le Propriétaire traite des Données personnelles à des fins de marketing direct, les Utilisateurs peuvent se reporter
                    aux sections correspondantes du présent document.
                    </p>
                    <p className="pb-1-3">Toute demande d’exercice des droits de l’Utilisateur peut être adressée au Propriétaire grâce aux coordonnées fournies dans le présent document. Ces
                    demandes peuvent
                    être exercées gratuitement et seront étudiées par le Propriétaire le plus tôt possible et toujours dans un délai d’un mois.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Politique relative aux cookies</h3>
                    <p className="pb-1-3">Ce site Web utilise des Cookies. Pour en savoir plus et obtenir une explication détaillée sur les cookies, l’Utilisateur peut consulter la <a href="https://www.iubenda.com/privacy-policy/27792693/cookie-policy" className="red">Politique relative aux Cookies</a>.</p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Informations supplémentaires sur le traitement et la collecte des données</h3>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Action en justice</h3>
                    <p className="pb-1-3">Les Données personnelles de l’Utilisateur peuvent être utilisées à des fins juridiques par le Responsable de traitement devant les tribunaux ou dans les
                    étapes pouvant
                    conduire à une action en justice résultant d’une utilisation inappropriée de ce site Web ou des Services connexes.
                    </p>
                    <p className="pb-1-3">L’Utilisateur est conscient du fait que le Responsable de traitement peut être amené à révéler des Données personnelles à la demande des autorités
                    publiques.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Journaux système et maintenance</h3>
                    <p className="pb-1-3">À des fins d’exploitation et de maintenance, ce site Web et tout service tiers peuvent collecter des fichiers qui enregistrent les interactions avec ce
                    site Web
                    (journaux système) ou utiliser à cette fin d’autres Données personnelles (telles que l’adresse IP).
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Informations non incluses dans les présentes</h3>
                    <p className="pb-1-3">De plus amples renseignements concernant la collecte ou le traitement des Données personnelles peuvent à tout moment être demandés à HELLOCARE. Veuillez
                    consulter les
                    coordonnées figurant au début du présent document.
                    </p>
                    <h3 className="font-size-1-2 uppercase bold pb-1-3">Définitions et références légales</h3>
                    <h4 className="font-400 pb-1-3">Données personnelles</h4>
                    <p className="pb-1-3">Toute information qui, directement, indirectement ou en relation avec d’autres informations – y compris un numéro d’identification personnel – permet
                    l’identification
                    ou l’identifiabilité d’une personne physique.
                    </p>
                    <h4 className="font-400 pb-1-3">Données d’utilisation</h4>
                    <p className="pb-1-3">Les informations collectées automatiquement par ce site Web (ou par des services tiers employés par ce site Web), qui peuvent inclure les adresses IP ou
                    les noms de
                    domaines des ordinateurs utilisés par les Utilisateurs qui utilisent ce site Web, les adresses URI (Uniform Resource Identifier ou identifiant uniforme de ressource),
                    l’heure de la demande, la méthode utilisée pour soumettre la demande au serveur, la taille du fichier reçu en réponse, le code numérique indiquant le statut de la
                    réponse du serveur (résultat favorable, erreur, etc.), le pays d’origine, les caractéristiques du navigateur et du système d’exploitation utilisés par l’Utilisateur,
                    les différents détails relatifs au temps par visite (p. ex. temps passé sur chaque page dans l’Application) et les détails relatifs au chemin suivi dans l’Application
                    avec une référence spéciale à la séquence des pages visitées, et d’autres paramètres concernant le système d’exploitation ou l’environnement informatique de
                    l’Utilisateur.
                    </p>
                    <h4 className="font-400 pb-1-3">Utilisateur(s)</h4>
                    <p className="pb-1-3">La personne utilisant ce site Web qui, sauf indication contraire, correspond à la Personne concernée.</p>
                    <h4 className="font-400 pb-1-3">Personne Concernée</h4>
                    <p className="pb-1-3">La personne physique à laquelle les Données personnelles font référence.</p>
                    <h4 className="font-400 pb-1-3">Sous-traitant (ou Responsable des données)</h4>
                    <p className="pb-1-3">La personne physique ou morale, l’autorité publique, l’institution ou tout autre organisme qui traite les Données personnelles pour le compte du
                    Responsable du
                    traitement, tel que décrit dans la présente politique de confidentialité.
                    </p>
                    <h4 className="font-400 pb-1-3">Responsable du traitement (ou Propriétaire)</h4>
                    <p className="pb-1-3">La personne physique ou morale, l’autorité publique, l’institution ou toute autre organisme qui, seul ou conjointement avec d’autres, détermine les
                    finalités et les
                    moyens du traitement de Données personnelles, y compris les mesures de sécurité concernant le fonctionnement et l’utilisation de ce site Web. Sauf mention contraire, le
                    Responsable du traitement est le Propriétaire de ce site Web.
                    </p>
                    <h4 className="font-400 pb-1-3">Site Web ou Application</h4>
                    <p className="pb-1-3">Les moyens par lesquels les Données personnelles de l’Utilisateur sont collectées et traitées.</p>
                    <h4 className="font-400 pb-1-3">Service</h4>
                    <p className="pb-1-3">
                    Le service fourni par HELLOCARE comme décrit dans les conditions s’y rapportant (le cas échéant) et sur ce site/cette application.
                    </p>
                    <h4 className="font-400 pb-1-3">Union Européenne (ou UE)</h4>
                    <p className="pb-1-3">Sauf indication contraire, toutes les références faites dans le présent document à l’Union européenne incluent tous les États membres actuels de l’Union
                    européenne et
                    de l’Espace économique européen.
                    </p>
                    <h4 className="font-400 pb-1-3">Cookies</h4>
                    <p className="pb-1-3">Traceur de données stocké dans l’appareil de l’Utilisateur.</p>
                    <h4 className="font-400 pb-1-3">Informations Légales</h4>
                    <p className="pb-1-3">La présente politique de confidentialité a été préparée en exécution des dispositions de plusieurs législations, notamment de l’article 13/14 du règlement
                    européen
                    2016/679 (règlement général sur la protection des données).
                    </p>
                    <p className="pb-1-3">Cette politique de confidentialité concerne uniquement les services développés et exploités par Hellocare, sauf indication contraire dans le présent
                    document.
                    </p>
                    <p className="pb-1-3">Dernière mise à jour : 27 août 2020.</p>
                </div>
    
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(IleraPolicy);